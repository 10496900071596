import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Embedded } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { formatCreatedAt, handleToastMutation, isSubArray } from '@/helpers';
import useCustomAppBrigde from '@/hooks/useCustomAppBrigde';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import translationSlice, { selectCurrentPageSelector } from '@/redux/slice/translation.slice';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  EmptyState,
  IndexTable,
  InlineGrid,
  InlineStack,
  LegacyCard,
  Modal as PolarisModal,
  Select,
  SkeletonBodyText,
  Text,
  TextContainer,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const LIMIT = 10;

function TranslationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopify = useCustomAppBrigde();
  const { t } = useTranslation(['translation', 'common']);
  const isSkipApi = useSelector(isSkipApiSelector);
  const currentPage = useSelector(selectCurrentPageSelector);
  const { data: translationByLanguage, isLoading: loading } = apiCaller.useGetTranslationQuery(
    { page: currentPage },
    { skip: isSkipApi },
  );
  const { data: ruleNameData, isLoading: ruleNameLoading } = apiCaller.useGetRuleNameQuery(undefined, { skip: isSkipApi });
  const [deleteTranslation, { isLoading }] = apiCaller.useDeleteTranslationMutation();
  const [fetchLanguages, languageStatus] = apiCaller.useLazyGetLanguageQuery();
  const [fetchTranslations] = apiCaller.useLazyGetTranslationQuery();

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [localeSelected, setLocaleSelected] = useState<string | undefined>(undefined);
  const [selectedId, setSelectedId] = useState<string>();

  const translations = useMemo(() => {
    return translationByLanguage?.translation?.map((item) => ({
      locale: item.locale,
      applyRule: item.applyRule,
      createdAt: new Date(item.createdAt).getTime(),
      lastUpdate: item.updatedAt ? new Date(item.updatedAt).getTime() : 0,
      status: item.status,
      id: item.id.toString(),
      applyRuleIds: item.ruleIdsApply,
    }));
  }, [translationByLanguage]);

  const ruleInfo = useMemo(() => {
    if (ruleNameData && ruleNameData.rules) {
      const rules = new Map(ruleNameData.rules.map(({ id, name }) => [id, name]));
      dispatch(translationSlice.actions.handleSetRuleInfo(ruleNameData.rules));
      return rules;
    } else return new Map();
  }, [dispatch, ruleNameData]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(
    translations ?? [],
  );

  const availableLanguages = useMemo(() => {
    return (
      languageStatus?.data?.data
        ?.filter((item) => !item.primary)
        ?.map((item) => ({
          value: item.locale,
          label: item.name,
        })) ?? []
    );
  }, [languageStatus?.data?.data]);

  const resourceName = {
    singular: 'translation',
    plural: 'translations',
  };

  const handleShowModal = useCallback(() => {
    setShowModal(true);
    shopify?.modal.show('add-language');
  }, [shopify]);

  const handleShowDeleteModal = useCallback(() => {
    setShowDeleteModal(true);
    shopify?.modal.show('delete-translation');
  }, [shopify]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    shopify?.modal.hide('add-language');
  }, [shopify]);

  const handleCloseDeleteModal = useCallback(() => {
    if (config.embedded !== Embedded.LIVE) {
      setShowDeleteModal(false);
      return;
    }
    setShowDeleteModal(false);
    shopify?.modal.hide('delete-translation');
    setSelectedId(undefined);
  }, [shopify]);

  const handleChangeLocale = useCallback((value: string) => {
    setLocaleSelected(value);
  }, []);

  const handleAddLanguage = useCallback(() => {
    dispatch(translationSlice.actions.handleSelectedLanguage(localeSelected ?? ''));
    setShowModal(false);
    shopify?.modal.hide('add-language');
    setTimeout(() => navigate(`${PATH.TRANSLATION_SETUP.pathname}/${localeSelected}/add`), 200);
  }, [dispatch, localeSelected, shopify?.modal, navigate]);

  const handleNextPage = useCallback(() => {
    fetchTranslations({ page: currentPage + 1 });
    dispatch(translationSlice.actions.handleChangeCurrentPage(currentPage + 1));
  }, [currentPage, dispatch, fetchTranslations]);

  const handlePreviousPage = useCallback(() => {
    fetchTranslations({ page: currentPage - 1 });
    dispatch(translationSlice.actions.handleChangeCurrentPage(currentPage - 1));
  }, [currentPage, dispatch, fetchTranslations]);

  const handleNavigateRule = useCallback(
    (id: string, locale: string) => {
      dispatch(translationSlice.actions.handleSelectedLanguage(locale));
      setTimeout(() => navigate(`${PATH.TRANSLATION_SETUP.pathname}/${locale}/${id}`), 150);
    },
    [dispatch, navigate],
  );

  const handleSelectRuleDelete = useCallback(
    (id: string) => {
      setSelectedId(id);
      handleShowDeleteModal();
    },
    [handleShowDeleteModal],
  );

  const handleDeleteRule = async () => {
    let ids: string[] = [];
    if (!selectedId) {
      ids = selectedResources;
    } else {
      ids.push(selectedId);
    }
    try {
      const res = await deleteTranslation({ ids });
      dispatch(handleToast(handleToastMutation(res, t)));
    } catch (err) {
      console.log(err);
    }
    handleCloseDeleteModal();
    clearSelection();
  };

  const rowMarkup = useMemo(() => {
    return translations
      ? translations.map((item, index) => (
        <IndexTable.Row id={item.id.toString()} onClick={() => { }} key={item.id.toString()} position={index} selected={selectedResources.includes(item.id)}>
          <IndexTable.Cell>{availableLanguages.find(val => val.value === item.locale)?.label}</IndexTable.Cell>
          <IndexTable.Cell>{isSubArray(item.applyRuleIds ?? [], ruleNameData?.rules.map((item) => item.id) ?? []) ? t('all_pop_up')
            : item.applyRuleIds?.map((val) => ruleInfo.get(val))?.filter((v) => !!v)?.join(',')?.slice(0, 50)}</IndexTable.Cell>
          <IndexTable.Cell>{formatCreatedAt(item.createdAt, t)}</IndexTable.Cell>
          <IndexTable.Cell>{formatCreatedAt(item.lastUpdate, t)}</IndexTable.Cell>
          <IndexTable.Cell>{item.status ? <Badge tone='success'>{t('published')}</Badge> : <Badge tone="enabled">{t('unpublished')}</Badge>}</IndexTable.Cell>
          <IndexTable.Cell>
            <InlineStack gap={"050"}>
              <Button icon={EditIcon} variant="plain" onClick={() => handleNavigateRule(item.id, item.locale)} />
              <Button icon={DeleteIcon} variant="plain" onClick={() => handleSelectRuleDelete(item.id)} />
            </InlineStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      ))
      : [];
  }, [
    translations,
    selectedResources,
    availableLanguages,
    ruleNameData?.rules,
    ruleInfo,
    handleNavigateRule,
    handleSelectRuleDelete,
    t
  ]);

  useEffect(() => {
    fetchLanguages({ shop: config.shop });
  }, [fetchLanguages]);

  return (
    <>
      {translations?.length && !loading && !ruleNameLoading ? (
        <Card>
          <Box paddingInlineEnd={"300"}>
            <InlineGrid columns="1fr auto">
              <Text variant="headingMd" as="h2">{t('translation_list')}</Text>
              <Button variant="primary" onClick={handleShowModal}>{t('add_language')}</Button>
            </InlineGrid>
          </Box>
          <Box paddingInline={"300"} paddingBlock={"400"}>
            <LegacyCard>
              <IndexTable
                onSelectionChange={handleSelectionChange}
                resourceName={resourceName}
                itemCount={translations?.length ?? 0}
                headings={[
                  { title: t('language') },
                  { title: t('pop_up') },
                  { title: t('common:created_at') },
                  { title: t('common:last_updated') },
                  { title: t('common:status') },
                  { title: t('common:action') }
                ]}
                promotedBulkActions={[
                  {
                    content: t('common:cancel'),
                    onAction: () => clearSelection()
                  },
                  {
                    content: t('common:delete'),
                    onAction: handleShowDeleteModal
                  }
                ]}
                selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                pagination={translations?.length ? {
                  hasNext: (translationByLanguage?.meta.currentPage ?? 1) < (translationByLanguage?.meta.totalPage ?? 1),
                  hasPrevious: translationByLanguage?.meta.currentPage !== 1,
                  label: // "showing_n_to_m_of_t_item": "Showing @ to # of & item(s)",
                    <Text as="p" variant="bodySm">
                      {t('showing_n_to_m_of_t_item', {
                        m: ((translationByLanguage?.meta?.currentPage ?? 1) - 1) * LIMIT + 1,
                        n: (((translationByLanguage?.meta?.currentPage ?? 1) - 1) * LIMIT + (translations?.length ?? 0)).toString(),
                        t: (translationByLanguage?.meta.total ?? 0).toString()
                      })}
                    </Text>,
                  onNext: handleNextPage,
                  onPrevious: handlePreviousPage,
                } : undefined}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
          </Box>

          {/* <div className="mt-16 pb-16">
                    {data && data?.meta.total ? (
                        <Pagination
                            label={
                                data?.meta.total
                                    ? `Showing ${(data.meta.currentPage - 1) * Number(popupListTable.limit) + 1} to ${Math.min(
                                        data.meta.currentPage * Number(popupListTable.limit),
                                        data?.meta.total,
                                    )} of ${data?.meta.total} items`
                                    : null
                            }
                            hasPrevious={!isFetching && data && data.meta.currentPage > 1}
                            onPrevious={() => {
                                if (data) {
                                    dispatch(handlePopupListTable({
                                        ...popupListTable,
                                        page: data && data.meta.currentPage - 1,
                                    }),
                                    );
                                }
                            }}
                            hasNext={!isFetching && data && data.meta.currentPage < Math.ceil(data.meta.total / Number(popupListTable.limit))}
                            onNext={() => {
                                if (data) {
                                    dispatch(handlePopupListTable({
                                        ...popupListTable,
                                        page: data.meta.currentPage + 1,
                                    }),
                                    );
                                }
                            }}
                        />
                    ) : null}
                </div> */}

          {/* <Modal
                open={state.isOpenModalDelete}
                onClose={handleCloseModalDelete}
                title={state.itemSelected === -1 ? 'Do you want to delete all selected rules' : 'Delete rule'}
                primaryAction={{
                    destructive: true,
                    content: 'Delete',
                    onAction: state.itemSelected === -1 ? handleDeleteSelected : handleDelete(state.itemSelected.toString()),
                    loading: deleteItemStatus.isLoading,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleCloseModalDelete,
                    },
                ]}
            >
                <Modal.Section>
                    <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
                </Modal.Section>
            </Modal> */}
        </Card>
      ) : (
        <LegacyCard sectioned title={t('translation_list')}>
          {(isLoading || ruleNameLoading) ? <SkeletonBodyText lines={5} /> :
            <EmptyState
              heading={t('translation_management')}
              action={ruleInfo.size !== 0 ? {
                content: t('add_language'),
                onAction: handleShowModal
              } : undefined}
              image={images.emptyState}
            >
              <RegularText>{t('translation_description')}.</RegularText>
              {ruleInfo.size === 0 ? <Box paddingBlockStart={"300"}><Tooltip content={t('create_first_popup')}>
                <Button disabled={true} variant="primary">{t('add_language')}</Button>
              </Tooltip>
              </Box> : null}
            </EmptyState>
          }
        </LegacyCard>
      )}
      {config.embedded === Embedded.LIVE ? (
        <>
          <Modal id="delete-translation" open={showDeleteModal} onHide={handleCloseDeleteModal} onShow={handleShowDeleteModal}>
            <TitleBar title={t('delete_translation')}>
              <button variant="primary" onClick={() => handleDeleteRule()} loading={isLoading ? "" : false}>{t('common:delete')}</button>
              <button onClick={handleCloseDeleteModal}>{t('common:cancel')}</button>
            </TitleBar>
            <Box paddingBlock={"200"} paddingInline={"400"}>
              <Text as="h3" variant="bodySm">
                {selectedId ? t('delete_single_confirm') :
                  t('delete_multiple_confirm')}</Text>
            </Box>
          </Modal>
          <Modal id="add-language" open={showModal} onHide={handleCloseModal} onShow={handleShowModal}>
            <TitleBar title={t('add_language')}>
              <button variant="primary" onClick={handleAddLanguage} disabled={(!localeSelected || !availableLanguages.length) ? true : undefined}>{t('common:add')}</button>
              <button onClick={handleCloseModal}>{t('common:cancel')}</button>
            </TitleBar>
            <Box paddingBlock={"200"} paddingInline={"400"} paddingBlockEnd={"300"}>
              <BlockStack gap={"200"}>
                <Text variant="headingSm" as="h3">{t('select_a_language')}</Text>
                <Select
                  options={availableLanguages}
                  label=""
                  value={localeSelected}
                  onChange={handleChangeLocale}
                  placeholder={t('common:select')}
                />
              </BlockStack>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <PolarisModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            title={t('delete_translation')}
            primaryAction={{
              content: t('common:delete'),
              onAction: () => handleDeleteRule()
            }}
            secondaryActions={[
              {
                content: t('common:cancel'),
                onAction: () => setShowDeleteModal(false)
              }
            ]}
          >
            <PolarisModal.Section>
              <TextContainer>
                <p>
                  {selectedId ? t('delete_single_confirm') : t('delete_multiple_confirm')}
                </p>
              </TextContainer>
            </PolarisModal.Section>
          </PolarisModal>

          <PolarisModal
            open={showModal}
            title={t('add_language')}
            primaryAction={{
              content: t('common:add'),
              onAction: () => handleAddLanguage(),
              disabled: !localeSelected || !availableLanguages.length
            }}
            onClose={() => setShowModal(false)}
            secondaryActions={[
              {
                content: t('common:cancel'),
                onAction: () => setShowModal(false)
              }
            ]}
          >

            <PolarisModal.Section>
              <BlockStack gap={"200"}>
                <Text variant="headingSm" as="h3">{t('select_a_language')}</Text>
                <Select
                  options={availableLanguages}
                  label=""
                  value={localeSelected}
                  onChange={handleChangeLocale}
                  placeholder={t('common:select')}
                />
              </BlockStack>
            </PolarisModal.Section>
          </PolarisModal>
        </>
      )}
    </>
  );
}
export default TranslationList;
