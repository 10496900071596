import { images } from '@/asset';
import { DateOrder, InputMethod } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { truncateText } from '@/helpers';
import { previewSelector } from '@/redux/slice/preview.slice';
import { Box, InlineStack, Link, Text, useBreakpoints } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PreviewStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { shopSelector, showWatermarkSelector } from '@/redux/slice/shop.slice';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

function Preview({
  mobileScreen,
  pcScreen,
  miniScreen,
  fullscreen,
}: {
  mobileScreen?: boolean;
  pcScreen?: boolean;
  miniScreen?: boolean;
  fullscreen?: boolean;
}) {
  const preview = useSelector(previewSelector);
  const showWatermark = useSelector(showWatermarkSelector);
  const { t } = useTranslation("popup");
  const location = useLocation();
  const { smDown } = useBreakpoints();
  const { shopifyPlan, email } = useSelector(shopSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip || email?.length > 0 });

  const generateOptions = (type: string) => {
    const options = [];
    if (type === 'day') {
      for (let i = 1; i <= 31; i++) {
        options.push(<option key={i}>{i.toString().padStart(2, '0')}</option>);
      }
    } else if (type === 'month') {
      const months = Object.values(preview.monthsLabel);
      months.forEach((month, index) => {
        options.push(<option key={index + 1}>{month}</option>);
      });
    } else if (type === 'year') {
      for (let i = 2024; i >= 1930; i--) {
        options.push(<option key={i}>{i}</option>);
      }
    }
    return options;
  };

  const renderDateInputs = (format: DateOrder) => {
    const order = {
      [DateOrder.YY_MM_DD]: ['year', 'month', 'day'],
      [DateOrder.MM_DD_YY]: ['month', 'day', 'year'],
      [DateOrder.DD_MM_YY]: ['day', 'month', 'year'],
      [DateOrder.DD_YY_MM]: ['day', 'year', 'month'],
      [DateOrder.YY_DD_MM]: ['year', 'day', 'month'],
      [DateOrder.MM_YY_DD]: ['month', 'year', 'day'],
    };

    return order?.[format]?.map((type: string) => (
      <select
        style={{
          pointerEvents: location.pathname === PATH.POP_UP_CUSTOMIZATION.pathname ? 'none' : 'initial',
          appearance: location.pathname === PATH.POP_UP_CUSTOMIZATION.pathname ? 'none' : undefined,
        }}
        key={type}
      >
        {generateOptions(type)}
      </select>
    ));
  };

  const removeWaterMark = () => {
    const sendText = 'I want to remove brandmark in the verification page';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PreviewStyled
      style={miniScreen ? { zoom: 1 / 3 } : mobileScreen ? { zoom: 1 / 2 } : pcScreen ? { zoom: 2 / 3 } : undefined}
      mobileScreen={mobileScreen}
      pcScreen={pcScreen}
      miniScreen={miniScreen}
      templateCSS={preview}
      fullscreen={fullscreen}
    >
      <div className="preview">
        <div className="preview-container">
          {preview?.logo && (
            <div className="preview-logo">
              <img src={preview.logo} alt="preview-logo" />
            </div>
          )}
          <div className="preview-title">{preview?.headingText ?? t('heading_preview')}</div>
          <div className="preview-content">{preview?.subHeadingText ?? t('sub_heading_preview')}</div>
          {String(preview.inputMethod) === InputMethod.BIRTHDATE_ENTRY && (
            <div className="preview-date">{renderDateInputs(preview.dateTimeFormat)}</div>
          )}
          <div className="preview-group-btn">
            <div className="submit-button">
              <Text variant="headingMd" as="h6">
                {smDown ? truncateText(preview?.submitButtonLabel ?? t('submit_button_label_preview'), 10) : truncateText(preview?.submitButtonLabel ?? t('submit_button_label_preview'), 20)}
              </Text>
            </div>
            <div className="cancel-button">
              <Text variant="headingMd" as="h6">
                {smDown ? truncateText(preview?.cancelButtonLabel ?? t('cancel_button_label_preview'), 10) : truncateText(preview?.cancelButtonLabel ?? t('cancel_button_label_preview'), 20)}
              </Text>
            </div>
          </div>

          {!fetchDataSettings.isLoading
            && showWatermark
            && location.pathname === PATH.CUSTOM_TEMPLATE.pathname
            && !(shopifyPlan === "staff" || email?.includes("@shopify.com")) ? (
            <div className="watermark-container">
              <div className="d-flex mt-16 mb-16">
                <div>{t('protected_by')}</div>
                <img className="ml-4" src={images.logo} alt="blockify" />
                <div
                  onClick={() =>
                    window.open(
                      'https://blockifyapp.com',
                      '_blank',
                    )
                  }
                  className="watermark-link"
                >
                  Blockify™
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {!fetchDataSettings.isLoading
        && showWatermark
        && location.pathname === PATH.CUSTOM_TEMPLATE.pathname
        && !(shopifyPlan === "staff" || email?.includes("@shopify.com")) ? (
        <>
          <Box paddingBlock="300">
            <InlineStack align="center">
              <Link onClick={removeWaterMark}>{t('click_to_remove_brand_mark')}</Link>
            </InlineStack>
          </Box>
        </>
      ) : null}
    </PreviewStyled>
  );
}

export default Preview;
