import BoldText from '@/components/BoldText';
import { DateOrder, InputMethod, PageType, StatusType } from '@/constants/enum';
import { choiceMethodList, optionAgeList, optionDateOrderList, status, triggerCondition } from '@/constants/options';
import SelectCustomPage from '@/pages/Settings/components/CustomPage';
import ProductOrCollectionList from '@/pages/Settings/components/ProductOrCollectionList';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Card, ChoiceList, Divider, RadioButton, Select, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function Info({ error, setError }: { error: string, setError: React.Dispatch<React.SetStateAction<string>> }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["popup", "common"]);
  const previewDetail = useSelector(previewSelector);
  const [errorProductOrCollection, setErrorProductOrCollection] = useState('');
  const [listUrl, setListUrl] = useState<{ value: string; isEdit: boolean; error?: string; index: number }[]>([]);
  const dateOrderHelpText = {
    [DateOrder.YY_MM_DD]: t('preview_yy_mm_dd'),
    [DateOrder.MM_DD_YY]: t('preview_mm_dd_yy'),
    [DateOrder.DD_MM_YY]: t('preview_dd_mm_yy'),
    [DateOrder.YY_DD_MM]: t('preview_yy_dd_mm'),
    [DateOrder.MM_YY_DD]: t('preview_mm_yy_dd'),
    [DateOrder.DD_YY_MM]: t('preview_dd_yy_mm')
  };
  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    const newValue = key === 'status' ? value === StatusType.Enable : value;

    dispatch(
      handleChangePreview({
        [key]: newValue,
      }),
    );
  };


  const handleChangeChoiceList = (key: keyof typeof previewDetail) => (selected: string[]) => {
    if (errorProductOrCollection) {
      setErrorProductOrCollection('');
    }

    dispatch(
      handleChangePreview({
        [key]: selected[0],
        showInPages: key === 'pageTypeToShow' ? [] : previewDetail.showInPages,
      }),
    );
  };
  return (
    <BlockStack gap="200">
      <Card>
        <BoldText>{t('pop_up_info')}</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="200">
          <Select
            value={!!previewDetail.status ? StatusType.Enable : StatusType.Disable}
            label={<BoldText>{t('common:status')}</BoldText>}
            options={status(t)}
            onChange={handleChangeTemplate('status')}
          />

          <TextField
            autoComplete=""
            label={<b>{t('common:name')}</b>}
            value={previewDetail?.name || ''}
            onChange={(value) => {
              if (!value?.trim()?.length) {
                setError(t('rule_name_required'));
              } else {
                if (error.length) {
                  setError("");
                }
              }
              handleChangeTemplate('name')(value);
            }}
            maxLength={255}
            showCharacterCount
            placeholder={t('enter_your_pop_up_name')}
            helpText={t('for_internal_reference_only_you_can_see_it')}
            error={error}
            requiredIndicator
          />

          <Select
            value={previewDetail.minAge}
            label={<BoldText>{t('verify_age')}</BoldText>}
            options={optionAgeList}
            onChange={handleChangeTemplate('minAge')}
          />

          <ChoiceList
            choices={choiceMethodList(t)}
            selected={[String(previewDetail.inputMethod)]}
            title={<BoldText>{t('method')}</BoldText>}
            onChange={handleChangeChoiceList('inputMethod')}
          />
          {previewDetail.inputMethod === InputMethod.BIRTHDATE_ENTRY &&
            <Select
              options={optionDateOrderList}
              label={<BoldText>{t('date_order')}</BoldText>}
              value={previewDetail.dateTimeFormat}
              onChange={handleChangeTemplate('dateTimeFormat')}
              helpText={dateOrderHelpText[previewDetail.dateTimeFormat]}
            />
          }
        </BlockStack>
      </Card>

      <Card>
        <BoldText>{t('condition')}</BoldText>
        <div className='mt-8 mb-8'>
          <Divider />
        </div>
        <BlockStack gap="200">
          <BlockStack gap="050">
            <BoldText>{t('display_pages')}</BoldText>
            <BlockStack>
              <RadioButton
                label={t('all_pages')}
                checked={previewDetail.pageTypeToShow === PageType.All}
                id={PageType.All}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.All])}
              />
              <RadioButton
                label={t('home_page')}
                checked={previewDetail.pageTypeToShow === PageType.Home}
                id={PageType.Home}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.Home])}
              />
              <RadioButton
                label={t('specific_collections')}
                checked={previewDetail.pageTypeToShow === PageType.SpecificCollections}
                id={PageType.SpecificCollections}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.SpecificCollections])}
              />
              {previewDetail.pageTypeToShow === PageType.SpecificCollections ?
                <ProductOrCollectionList
                  errorProductOrCollection={errorProductOrCollection}
                  setErrorProductOrCollection={setErrorProductOrCollection}
                /> : null}
              <RadioButton
                label={t('specific_products')}
                checked={previewDetail.pageTypeToShow === PageType.SpecificProducts}
                id={PageType.SpecificProducts}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.SpecificProducts])}
              />
              {previewDetail.pageTypeToShow === PageType.SpecificProducts ?
                <ProductOrCollectionList
                  errorProductOrCollection={errorProductOrCollection}
                  setErrorProductOrCollection={setErrorProductOrCollection}
                /> : null}
              <RadioButton
                label={t('custom')}
                checked={previewDetail.pageTypeToShow === PageType.Custom}
                id={PageType.Custom}
                onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.Custom])}
              />
              {
                previewDetail.pageTypeToShow === PageType.Custom ?
                  <SelectCustomPage listUrl={listUrl} setListUrl={setListUrl} /> : null
              }
            </BlockStack>
          </BlockStack>
          {/* <Collapsible
            open={previewDetail.pageTypeToShow !== PageType.All}
            id="select-specific-page"
            expandOnPrint
            transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          >
            <Select
              value={previewDetail.pageTypeToShow}
              options={choiceDisplayOptionSpecific}
              label={<BoldText>Select specific pages</BoldText>}
              onChange={(value) => handleChangeChoiceList('pageTypeToShow')([value])}
            />
            <ProductOrCollectionList
              errorProductOrCollection={errorProductOrCollection}
              setErrorProductOrCollection={setErrorProductOrCollection}
            />
            <SelectCustomPage listUrl={listUrl} setListUrl={setListUrl} />
          </Collapsible> */}
          <ChoiceList
            choices={triggerCondition(t)}
            selected={[String(previewDetail.applyCustomer)]}
            title={<BoldText>{t('trigger_condition')}</BoldText>}
            onChange={handleChangeChoiceList('applyCustomer')}
          />
        </BlockStack>
      </Card>
    </BlockStack>
  )
}

export default Info