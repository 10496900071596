import RenderRouter from '@/routes';
import { createApp } from '@shopify/app-bridge';
import { NavMenu } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import vi from '@shopify/polaris/locales/vi.json';
import fr from '@shopify/polaris/locales/fr.json';
import it from '@shopify/polaris/locales/it.json';
import de from '@shopify/polaris/locales/de.json';
import es from '@shopify/polaris/locales/es.json';
import hi from './translate/hindu.json';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { config } from './config';
import { Embedded } from './constants/enum';
import { PATH } from './constants/path';
import authSlice, { isSkipApiSelector } from './redux/slice/auth.slice';
import { useTranslation } from 'react-i18next';

interface NavigationProps {
  label: string;
  pathname: string;
  url: {
    pathname: string;
    search: string;
  };
}

const navigationLinks: NavigationProps[] = [
  {
    label: 'Store verification',
    pathname: PATH.POP_UP_CUSTOMIZATION.pathname,
    url: PATH.POP_UP_CUSTOMIZATION,
  },
  {
    label: 'Checkout verification',
    pathname: PATH.CHECKOUT_VERIFICATION.pathname,
    url: PATH.CHECKOUT_VERIFICATION,
  },
  {
    label: 'Translation',
    pathname: PATH.TRANSLATION.pathname,
    url: PATH.TRANSLATION,
  },
  {
    label: 'Terms and conditions',
    pathname: PATH.TERM_AND_CONDITION.pathname,
    url: PATH.TERM_AND_CONDITION,
  },
  {
    label: 'Settings',
    pathname: PATH.SETTINGS.pathname,
    url: PATH.SETTINGS,
  },
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const { i18n } = useTranslation();
  // const { data } = apiCaller.useGetSettingsQuery(undefined, { skip: isSkip });
  // useEffect(() => {
  //   if (data?.setting) {
  //     const newData: IPreview = {
  //       cacheTime: data?.setting?.cacheTime?.toString(),
  //       cancelButtonBackground: String(data?.setting?.cancelButtonBackground?.toString()),
  //       cancelButtonLabel: data?.setting?.cancelButtonLabel?.toString(),
  //       cancelButtonTextColor: data?.setting?.cancelButtonTextColor?.toString(),
  //       customCss: data?.setting?.customCss?.toString() || '',
  //       dateTimeFormat: data?.setting?.dateTimeFormat,
  //       errorMessage: data?.setting?.errorMessage?.toString(),
  //       headingColor: data?.setting?.headingColor?.toString(),
  //       headingSize: data?.setting?.headingSize?.toString(),
  //       headingText: data?.setting?.headingText?.toString(),
  //       id: data?.setting?.id,
  //       inputMethod: data?.setting?.inputMethod?.toString() as InputMethod,
  //       lastUpdate: data?.setting?.lastUpdate,
  //       logo: data?.setting?.logo || '',
  //       mainBackground: data?.setting?.mainBackground?.toString(),
  //       mainBackgroundType: data?.setting?.mainBackgroundType?.toString() as BackgroundType,
  //       minAge: data?.setting?.minAge?.toString(),
  //       monthsLabel: data?.setting?.monthsLabel,
  //       pageTypeToShow: data?.setting?.pageTypeToShow?.toString() as PageType,
  //       popupBackground: data?.setting?.popupBackground?.toString(),
  //       redirectUrl: data?.setting?.redirectUrl,
  //       shop: data?.setting?.shop,
  //       showInPages: data?.setting?.showInPages ?? [],
  //       showWatermark: !!data?.setting?.showWatermark,
  //       subHeadingColor: data?.setting?.subHeadingColor?.toString(),
  //       subHeadingSize: data?.setting?.subHeadingSize?.toString(),
  //       subHeadingText: data?.setting?.subHeadingText?.toString(),
  //       submitButtonBackground: data?.setting?.submitButtonBackground?.toString(),
  //       submitButtonLabel: data?.setting?.submitButtonLabel?.toString(),
  //       submitButtonTextColor: data?.setting?.submitButtonTextColor?.toString(),
  //       showCustomMonthLabel: data?.setting?.showCustomMonthLabel,
  //       customPages: data.setting?.customPages ?? [],
  //       status: !!data?.setting?.status,
  //       name: data?.setting?.name?.toString(),
  //       triggerCondition: data?.setting?.triggerCondition?.toString() as TriggerType,
  //     };
  //     dispatch(handleCustomizeTemplate(newData));
  //   }
  // }, [data?.setting, data?.setting?.id, dispatch]);

  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_MODE === 'live',
  };

  if (isSkip) {
    const getToken = async () => {
      try {
        dispatch(authSlice.actions.handleLoading(true));
        const app = createApp(configApp);
        const sessionToken = await getSessionToken(app);
        dispatch(
          authSlice.actions.handleToken({
            token: sessionToken,
            expiredTime: Date.now() + 60 * 1000,
            isLoading: false,
          }),
        );
      } catch (err) {
        dispatch(authSlice.actions.handleLoading(false));
      }
    };
    getToken();
  }

  const languageJson = useMemo(() => {
    switch (i18n.language) {
      case 'it':
        return it;
      case 'de':
        return de;
      case 'es':
        return es;
      case 'fr':
        return fr;
      case 'vi':
        return vi;
      case 'hi':
        return hi;
      default:
        return en;
    }
  }, [i18n.language]);

  useEffect(() => {
    if (config && config.role !== 'admin') {
      const timer = setTimeout(() => {
        var crisp = document.createElement('script');
        crisp.setAttribute('src', `${process.env.REACT_APP_API_END_POINT}file/crisp.js?shop=${config.shop}`);
        crisp.setAttribute('id', 'age-verification-crisp');
        crisp.appendChild(document.createTextNode(``));
        document.body.appendChild(crisp);
      }, 2500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  useEffect(() => {
    i18n.loadNamespaces(['common', 'dashboard', 'popup', 'setting', 'checkout', 'translation', 'term-condition']).then(() => console.log("Language loaded"));
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <AppProvider i18n={languageJson} i18nIsDynamicList>
      {config.embedded === Embedded.LIVE ? (
        <>
          <NavMenu>
            <a
              href={PATH.DEFAULT.pathname}
              rel="home"
              onClick={(e) => {
                e.preventDefault();
                navigate(PATH.DEFAULT);
              }}
            >
              Home
            </a>
            {navigationLinks.map((item) => {
              return (
                <a
                  id={item.pathname}
                  key={item.pathname}
                  href={item.pathname}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(item.url);
                  }}
                >
                  {item.label}
                </a>
              );
            })}
          </NavMenu>
          <RenderRouter />
        </>
      ) : (
        <RenderRouter />
      )}
    </AppProvider>
  );
}

export default App;
