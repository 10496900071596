import circleTick from '@/asset/dashboard/tick-circle.png';
import BoldText from '@/components/BoldText';
import { link } from '@/constants/link';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import bannerSlice, { showThingsToDoNextSelector } from '@/redux/slice/banner.slice';
import dashboardSlice, { stepSelector } from '@/redux/slice/Dashboard/dashboard.slice';
import {
  ActionList,
  Badge,
  BlockStack,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Popover,
  ProgressBar,
  Text
} from '@shopify/polaris';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThingToDoNextStyled } from './styled';

interface Props {
  embedStatus?: boolean;
  hasCustomize: boolean;
  hasTestApp: boolean;
  shop: string;
  isLoadingFetchDataSettings: boolean;
}

function ThingsToDoNext({ embedStatus, hasCustomize, hasTestApp, shop, isLoadingFetchDataSettings }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard", "common"]);

  const showThingsToDoNext = useSelector(showThingsToDoNextSelector);
  const step = useSelector(stepSelector);

  const [fetchDataSettings] = apiCaller.useLazyGetGeneralDetailQuery();
  const [trackAction] = apiCaller.useTrackActionMutation();

  const totalSteps = Object.keys(step).length;
  const completedCount = Object.values(step).filter(Boolean).length;
  const progressBar = (completedCount / totalSteps) * 100;

  const [isLoadingCheckEmbedded, setIsLoadingCheckEmbedded] = useState(true);
  const [popoverActive, setPopoverActive] = useState(false);
  const [collapse, setCollapse] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  const togglePopoverActive = useCallback(() => {
    setPopoverActive(!popoverActive);
  }, [popoverActive]);

  const activator = (
    <div className="btn-action">
      <Button onClick={togglePopoverActive} disclosure variant="plain" icon={MenuHorizontalIcon}></Button>
    </div>
  );

  const handleTurnOffThingsToDoNext = () => {
    dispatch(bannerSlice.actions.handleShowThingsToDoNext(false));
  };

  const handleCollapse = (key: keyof typeof collapse) => () => {
    const result = { ...collapse };
    Object.keys(result).forEach((k) => {
      result[k as keyof typeof result] = false;
    });
    setCollapse({
      ...result,
      [key]: !collapse[key],
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkEmbed = () => {
        dispatch(
          dashboardSlice.actions.handleStep({
            ...step,
            step1: !!embedStatus,
          }),
        );
      };
      checkEmbed();
      setIsLoadingCheckEmbedded(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [embedStatus, progressBar, completedCount, dispatch]);

  useEffect(() => {
    const newStep = { ...step };

    if (hasCustomize) {
      newStep.step2 = hasCustomize;
    }
    if (hasTestApp) {
      newStep.step3 = hasTestApp;
    }

    dispatch(dashboardSlice.actions.handleStep(newStep));
    // eslint-disable-next-line
  }, [dispatch, hasCustomize, hasTestApp]);

  useEffect(() => {
    if (embedStatus && hasCustomize && hasTestApp) {
      // Nếu tất cả trạng thái đều `true`, đóng tất cả các step
      setCollapse({ step1: false, step2: false, step3: false });
    } else if (!isLoadingCheckEmbedded) {
      // Nếu có trạng thái `false`, mở step đầu tiên có `false`
      if (!embedStatus) {
        setCollapse({ step1: true, step2: false, step3: false });
      } else if (!hasCustomize) {
        setCollapse({ step1: false, step2: true, step3: false });
      } else if (!hasTestApp) {
        setCollapse({ step1: false, step2: false, step3: true });
      }
    }
  }, [embedStatus, hasCustomize, hasTestApp, isLoadingCheckEmbedded]);

  return (
    <ThingToDoNextStyled>
      <Collapsible
        open={showThingsToDoNext}
        id="basic-collapsible-1"
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        <div className="btn-cancel">
          <Popover active={popoverActive} activator={activator} autofocusTarget="first-node" onClose={togglePopoverActive}>
            <ActionList actionRole="menuitem" items={[{ content: t('dismiss_action'), onAction: handleTurnOffThingsToDoNext }]} />
          </Popover>
        </div>
        <Card>
          <BoldText>{t('setup_guide')}</BoldText>
          <div className="progress-container">
            <div className="progress">
              <div className="current-step">
                <Text variant="bodyMd" as="p">{t('completed_step', { complete: completedCount, total: totalSteps })}</Text>
              </div>
              <ProgressBar tone="primary" progress={progressBar} size="small" />
            </div>
          </div>

          <div className={collapse.step1 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
            <div
              className="thing-to-do-title"
              onClick={handleCollapse('step1')}
              aria-expanded={collapse.step1}
              aria-controls="basic-collapsible-1"
            >
              <BlockStack>
                <InlineStack gap={'200'} align="center">
                  <Button
                    loading={isLoadingCheckEmbedded}
                    id="circle"
                    variant="plain"
                    icon={step.step1 ? <img src={circleTick} alt="circle-tick" /> : <div className="circle" />}
                  />
                  {collapse.step1 ? (
                    <Text variant="headingSm" as="h6">
                      {t('activate_app_embed')}
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      {t('activate_app_embed')}
                    </Text>
                  )}
                  {isLoadingCheckEmbedded ? null : (
                    <Badge tone={embedStatus ? 'success' : 'attention'}>{embedStatus ? t('common:on') : t('common:off')}</Badge>
                  )}
                </InlineStack>
              </BlockStack>
            </div>
            <div className="step">
              <Collapsible
                open={collapse.step1}
                id="basic-collapsible-1"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <div>
                  <Text variant="bodyMd" as="p">
                    {t('activate_app_embed_complete_setup')}
                  </Text>
                </div>
                <div className="mt-8 mb-8">
                  <Button variant="primary" target="_blank" url={link.isOpenAppEmbedUrl}>
                    {t('go_to_shopify_theme_editor')}
                  </Button>
                </div>
              </Collapsible>
            </div>
          </div>
          <div className={collapse.step2 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
            <div
              aria-expanded={collapse.step2}
              aria-controls="basic-collapsible-2"
              className="thing-to-do-title"
              onClick={handleCollapse('step2')}
            >
              <BlockStack>
                <InlineStack gap={'200'} align="center">
                  <Button
                    loading={isLoadingFetchDataSettings}
                    id="circle"
                    variant="plain"
                    icon={step.step2 ? <img src={circleTick} alt="circle-tick" /> : <div className="circle" />}
                  />
                  {collapse.step2 ? (
                    <Text variant="headingSm" as="h6">
                      {t('customize_settings')}
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      {t('customize_settings')}
                    </Text>
                  )}
                </InlineStack>
              </BlockStack>
            </div>
            <div className="step">
              <Collapsible
                open={collapse.step2}
                id="basic-collapsible-2"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <div>
                  <Text variant="bodyMd" as="p">
                    {t('select_popup_template')}
                  </Text>
                </div>
                <div className="mb-8 mt-8">
                  <Button variant="primary" onClick={() => navigate(PATH.CUSTOM_TEMPLATE)}>
                    {t('customize_popup')}
                  </Button>
                </div>
              </Collapsible>
            </div>
          </div>
          <div className={collapse.step3 ? 'thing-to-do-step-open' : 'thing-to-do-step-close'}>
            <div
              className="thing-to-do-title"
              onClick={handleCollapse('step3')}
              aria-expanded={collapse.step3}
              aria-controls="basic-collapsible-3"
            >
              <BlockStack>
                <InlineStack gap={'200'} align="center">
                  <Button
                    id="circle"
                    variant="plain"
                    loading={isLoadingFetchDataSettings}
                    icon={step.step3 ? <img src={circleTick} alt="circle-tick" /> : <div className="circle" />}
                  />
                  {collapse.step3 ? (
                    <Text variant="headingSm" as="h6">
                      {t('test_your_setup')}
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="p">
                      {t('test_your_setup')}
                    </Text>
                  )}
                </InlineStack>
              </BlockStack>
            </div>
            <div className="step">
              <Collapsible
                open={collapse.step3}
                id="basic-collapsible-3"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <div>
                  <Text variant="bodyMd" as="p">
                    {t('test_popup_visibility_support')}
                  </Text>
                </div>
                <div className="mt-8 mb-8">
                  <Button
                    variant="primary"
                    target="_blank"
                    url={`https://${shop}`}
                    onClick={() => {
                      trackAction('test.setup');
                      fetchDataSettings('');
                    }}
                  >
                    {t('test_the_setup')}
                  </Button>
                </div>
              </Collapsible>
            </div>
          </div>
        </Card>
      </Collapsible>
    </ThingToDoNextStyled>
  );
}

export default ThingsToDoNext;
