import { images } from '@/asset';
import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import { config } from '@/config';
import { capitalizeFirstLetter } from '@/helpers/string';
import userPlans from '@/hooks/userPlans';
import {
  BannerStyleType,
  handleChangeSetupVerify,
  handleSetupErrorVerify,
  setupErrorVerifySelector,
  setupVerifySelector,
} from '@/redux/slice/checkoutVerification.slice';
import { useTranslation } from 'react-i18next';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { BlockStack, Box, Button, Card, Divider, InlineGrid, Link, Text, TextField } from '@shopify/polaris';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const bannerStyles = [
  {
    value: BannerStyleType.Info,
    icon: images.iconInfo,
    colorPrimary: 'rgba(222, 222, 222, 1)',
    colorSecondary: 'rgba(245, 245, 245, 1)',
  },
  {
    value: BannerStyleType.Warning,
    icon: images.iconWarning,
    colorPrimary: 'rgba(255, 222, 178, 1)',
    colorSecondary: 'rgba(255, 245, 235, 1)',
  },
  {
    value: BannerStyleType.Success,
    icon: images.iconSuccess,
    colorPrimary: 'rgba(177, 242, 180, 1)',
    colorSecondary: 'rgba(234, 251, 234, 1)',
  },
  {
    value: BannerStyleType.Critical,
    icon: images.iconCritical,
    colorPrimary: 'rgba(253, 216, 216, 1)',
    colorSecondary: 'rgba(254, 246, 246, 1)',
  },
];

interface Props {
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BannerVerify: React.FC<Props> = ({ setActiveModal }) => {
  const dispatch = useDispatch();
  const { shopifyPlanPlus } = userPlans();
  const setupVerify = useSelector(setupVerifySelector);
  const setupVerifyError = useSelector(setupErrorVerifySelector);
  const { t } = useTranslation(["checkout", "common"]);
  const isShowContextualBar = useSelector(isShowSelector);

  const handleChangeSetup = (key: keyof typeof setupVerify) => (value: string | boolean) => {
    dispatch(
      handleChangeSetupVerify({
        [key]: value,
      }),
    );
  };

  const handlePreviewCheckoutEditor = useCallback(() => {
    if (isShowContextualBar && config.embedded === '1') {
      setActiveModal((props) => !props);
    } else {
      window.open(
        `https://admin.shopify.com/store/${config.shop.replace('.myshopify.com', '')}/settings/checkout/editor`,
        '_bank',
      );
    }
  }, [isShowContextualBar, setActiveModal]);

  return (
    <BlockStack gap="200">
      {shopifyPlanPlus && (
        <Card>
          <div className="mb-8">
            <BlockStack gap="200">
              <Text as="h4" variant="headingMd">
                {t('banner_style')}
              </Text>
              <Divider />
              <>
                <Text as="h6">{t('style')}</Text>
                <InlineGrid columns={2} gap="200">
                  {bannerStyles.map((banner) => (
                    <BlockStack inlineAlign="center" key={banner.value} gap="100">
                      <div
                        onClick={() => handleChangeSetup('bannerStyle')(banner.value)}
                        style={{
                          width: '100%',
                          border: `1px solid ${banner.value === setupVerify.bannerStyle ? 'blue' : 'transparent'}`,
                          padding: '4px',
                          borderRadius: '6px',
                          cursor: 'pointer',
                        }}
                      >
                        <BannerCheckoutUI banner={banner} />
                      </div>
                      <Text as="p">{capitalizeFirstLetter(banner.value)}</Text>
                    </BlockStack>
                  ))}
                </InlineGrid>
              </>
            </BlockStack>
          </div>
        </Card>
      )}

      <Card>
        <div className="mb-8">
          <BlockStack gap="200">
            <Text as="h4" variant="headingMd">
            {t('text_customization')}
            </Text>
            <Divider />

            <BlockStack gap="500">
              <TextField
                requiredIndicator
                label={t('heading')}
                value={setupVerify.heading ?? t('must_be_18_years_old')}
                autoComplete=""
                onChange={(value) => {
                  if (setupVerifyError.heading) dispatch(handleSetupErrorVerify({ heading: '' }));
                  handleChangeSetup('heading')(value);
                }}
                onBlur={() => {
                  if (setupVerify.heading?.trim().length === 0)
                    dispatch(
                      handleSetupErrorVerify({
                        heading: t('value_is_required'),
                      }),
                    );
                }}
                error={setupVerifyError.heading}
                showCharacterCount
                maxLength={255}
              />

              {shopifyPlanPlus && (
                <TextField
                  requiredIndicator
                  label={t('description')}
                  value={setupVerify.subHeading}
                  autoComplete=""
                  onChange={(value) => {
                    if (setupVerifyError.subHeading) dispatch(handleSetupErrorVerify({ subHeading: '' }));
                    handleChangeSetup('subHeading')(value);
                  }}
                  onBlur={() => {
                    if (setupVerify.subHeading.trim().length === 0)
                      dispatch(
                        handleSetupErrorVerify({
                          subHeading: t('value_is_required'),
                        }),
                      );
                  }}
                  error={setupVerifyError.subHeading}
                  showCharacterCount
                  maxLength={255}
                />
              )}
            </BlockStack>
          </BlockStack>
        </div>
      </Card>

      {shopifyPlanPlus ? (
        <Card>
          <BlockStack gap={'025'}>
            <Text variant="headingMd" as="h2">
            {t('configuration')}
            </Text>
            <Text variant="bodyMd" as="span">
            {t('customize_ui_content')}.{' '}
              <Link
                target="_blank"
                url="https://blockifyapp.com/knowledge-base/uncategorized/set-up-age-verification-at-checkout-for-shopify-plus-only/"
              >
                {t('learn_more')}
              </Link>
            </Text>
          </BlockStack>
          <Box paddingBlockStart={'300'}>
            <Button size="slim" onClick={handlePreviewCheckoutEditor}>
            {t('go_to_checkout_editor')}
            </Button>
          </Box>
        </Card>
      ) : null}
    </BlockStack>
  );
};

export default BannerVerify;
