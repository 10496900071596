import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getSomeDaysAgo } from '@/helpers';
import { RootState } from '../../store';
// Define the initial state using that type
export interface IDateTime {
  startDate: Date;
  endDate: Date;
}
export interface IStep {
  step1: boolean;
  step2: boolean;
  step3: boolean;
}
const now = new Date();
const initialState: {
  step: IStep;
  titleBtnDatePicker: string;
  datePicker: IDateTime;
  selected: number;
} = {
  step: {
    step1: false,
    step2: false,
    step3: false,
  },
  titleBtnDatePicker: 'Last 7 days',
  datePicker: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
  },
  selected: 3,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleStep: (state, action: PayloadAction<IStep>) => {
      state.step = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
    handleSelectOptionDatePicker: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    handleDatePicker: (state, action: PayloadAction<IDateTime>) => {
      state.datePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const stepSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.step,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleBtnDatePicker,
);
export const selectedDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.selected,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.datePicker,
);

export default dashboardSlice;
