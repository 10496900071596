import Layout from '@/components/layout';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { Box, Button, Card, Icon, InlineGrid, Text } from '@shopify/polaris';
import { LanguageTranslateIcon } from '@shopify/polaris-icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TranslationStyled } from './styled';
import TranslationList from './components/Table';
import { useTranslation } from 'react-i18next';

function Translation() {
  const isSkipApi = useSelector(isSkipApiSelector);
  const { t } = useTranslation('translation');
  const [fetchLanguages, languageStatus] = apiCaller.useLazyGetLanguageQuery();

  const generalDetailQuerySettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkipApi });

  const defaultLanguage = languageStatus && languageStatus?.data?.data?.find((item) => item.primary);

  const handleRefreshData = () => {
    fetchLanguages({ shop: config.shop });
  };

  useEffect(() => {
    fetchLanguages({ shop: config.shop });
  }, [fetchLanguages]);

  return (
    <Layout
      title={t('translation')}
      subtitle={t('translate_widgets_multiple_languages')}
      primaryAction={
        <Button onClick={handleRefreshData} loading={languageStatus.isFetching}>
          {t('refresh_data')}
        </Button>
      }
    >
      <TranslationStyled>
        <Card>
          <InlineGrid columns={'auto 1fr'} gap={'050'}>
            <Icon source={LanguageTranslateIcon} />
            <Text as="h2" variant="headingSm">
              {t('default_language')}: {defaultLanguage?.name}
            </Text>
          </InlineGrid>
          <Text as="h2" variant="bodySm">
            {t('all_widgets_identified')}
          </Text>
          <Box paddingBlockStart={'200'}>
            <Button
              onClick={() =>
                window.open(
                  `https://admin.shopify.com/store/${generalDetailQuerySettings.data?.data?.shop.replace(
                    '.myshopify.com',
                    '',
                  )}/settings/languages`,
                  '_blank',
                )
              }
            >
              {t('change_default')}
            </Button>
          </Box>
        </Card>
        <div className="mt-16">
          <TranslationList />
        </div>
      </TranslationStyled>
    </Layout>
  );
}

export default Translation;
