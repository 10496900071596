import BoldSmallText from '@/components/BoldSmallText';
import BoldText from '@/components/BoldText';
import DatePicker from '@/components/datePicker';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import dashboardSlice, { datePickerSelector, titleBtnDatePickerSelector } from '@/redux/slice/Dashboard/dashboard.slice';
import { Card, InlineGrid } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { OverviewStyled } from './styled';
import { useTranslation } from 'react-i18next';

function Overview() {
  const dispatch = useDispatch();
  const datePicker = useSelector(datePickerSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { t } = useTranslation("dashboard");
  const getOverview = apiCaller.useGetOverviewQuery({
    startDate: dateToTimeStamp(datePicker.startDate).toString(),
    endDate: dateToTimeStamp(datePicker.endDate).toString()
  }, { skip: isSkip })

  const overview = [
    {
      title: t('total_verification'),
      data: getOverview.data?.data.total || 0,
    },
    {
      title: t('verified'),
      data: getOverview.data?.data.verified || 0,
    },
    {
      title: t('unverified'),
      data: getOverview.data?.data.unverified || 0,
    }
  ];
  const handleSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(dashboardSlice.actions.handleDatePicker({
      ...datePicker,
      startDate,
      endDate
    }));
  };

  const handleSaveTitleBtnDatePicker = (title: string, selected: number) => {
    dispatch(dashboardSlice.actions.handleTitleBtnDatePicker(title));
    dispatch(dashboardSlice.actions.handleSelectOptionDatePicker(selected));
  };
  return (
    <OverviewStyled>
      <Card>
        <div className='space-between'>
          <BoldText>{t('overview')}</BoldText>
          <DatePicker
            titleButton={titleBtn}
            setTitleButton={handleSaveTitleBtnDatePicker}
            startDate={datePicker.startDate}
            endDate={datePicker.endDate}
            onSave={handleSaveDatePicker}
            isShowSelectedTime={false}
            conditions
          />
        </div>
        <div className='mt-16'>
          <InlineGrid gap="600" columns={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 3 }}>
            {overview.map((item, index) => {
              return (
                <div key={index} className='overview-item'>
                  <div className='overview-title'>
                    <BoldSmallText>{item.title}</BoldSmallText>
                  </div>
                  <div className='overview-value'>{item.data}</div>
                </div>
              );
            })}
          </InlineGrid>
        </div>
      </Card>
    </OverviewStyled >
  );
}

export default Overview;
