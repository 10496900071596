import { SkeletonDisplayText, SkeletonDisplayTextProps } from '@shopify/polaris';
import { SkeletonCustomStyled } from './styled';

export interface SkeletonCustomProps extends SkeletonDisplayTextProps {
  height?: string;
  opacity?: boolean;
}
const SkeletonCustom = (props: SkeletonCustomProps) => {
  return (
    <SkeletonCustomStyled opacity={props.opacity} height={props.height}>
      <SkeletonDisplayText {...props}></SkeletonDisplayText>
    </SkeletonCustomStyled>
  );
};

export default SkeletonCustom
