import ColorPickerCustom from '@/components/ColorPickerCustom';
import RegularText from '@/components/RegularText';
import { BackgroundColor } from '@/constants/enum';
import { openCrisp } from '@/helpers';
import { handleChangeSetupTermAndCondition, setupTermAndConditionSelector, errorsSelector, setErrorsTermAndCondition } from '@/redux/slice/termAndCondition.slice';
import { BlockStack, Card, Divider, InlineStack, Link, Text, TextField } from '@shopify/polaris';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props { }

const CheckBox: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["term-condition", "common"]);
  const termAndCondition = useSelector(setupTermAndConditionSelector);
  const errors = useSelector(errorsSelector);

  const handleChangeCheckbox = useCallback((key: keyof typeof termAndCondition) => (value: string) => {
    if (key === "messageSize") {
      value = value.replace(/-/g, '');
      const isValidNumber = /^[0-9]\d*$/.test(value);
      if (isValidNumber) {
        let numericValue = parseInt(value, 10);
        value = numericValue.toString();
      } else {
        value = '';
      }
    }

    const updatedErrors = { ...errors };
    if ((key === 'messageContent' || key === 'keyword') && value !== null && value !== undefined && !value.trim()) {
      updatedErrors[key] = t('field_required');
    } else {
      delete updatedErrors[key];
    }

    const updatedTermAndCondition = {
      ...termAndCondition,
      [key]: value,
    };

    dispatch(handleChangeSetupTermAndCondition(updatedTermAndCondition));
    dispatch(setErrorsTermAndCondition(updatedErrors));
  }, [dispatch, t, termAndCondition, errors]);

  return (
    <BlockStack gap="200">
      <Card>
        <div className="mb-8">
          <BlockStack gap="200">
            <Text as="h4" variant="headingMd">
              {t("message_key")}
            </Text>
            <Divider />
            <BlockStack gap="200">
              <TextField
                autoComplete="off"
                label={t('text_key')}
                value={termAndCondition.messageContent ?? t('terms_agreement_text_field')}
                onChange={handleChangeCheckbox('messageContent')}
                maxLength={255}
                showCharacterCount
                requiredIndicator
                error={errors['messageContent']}
              />
              <TextField
                autoComplete="off"
                label={t('keyword')}
                value={termAndCondition.keyword ?? t('terms_conditions_default')}
                onChange={handleChangeCheckbox('keyword')}
                requiredIndicator
                error={errors['keyword']}
              />
              <TextField
                autoComplete="off"
                label={t("link_to_keyword ")}
                value={termAndCondition.link || ''}
                onChange={handleChangeCheckbox('link')}
                prefix="https://"
                helpText={t("url_hyperlink_keyword_match")}
              />
              <TextField
                autoComplete="off"
                label={t('size_key')}
                value={termAndCondition.messageSize}
                onChange={handleChangeCheckbox('messageSize')}
                suffix="px"
              />
              <ColorPickerCustom
                onChange={handleChangeCheckbox('messageColor')}
                label={t('color_key')}
                color={termAndCondition.messageColor}
                keyColor="messageColor"
                defaultColor={BackgroundColor.Black}
              />
              <TextField
                autoComplete="off"
                label={t('error_message_optional')}
                value={termAndCondition.messageError}
                onChange={handleChangeCheckbox('messageError')}
                placeholder={t('enter_error_message')}
                maxLength={255}
                showCharacterCount
              />
              <InlineStack gap={"050"}>
                <RegularText>{t("customization_options_needed")} <Link onClick={openCrisp}>{t("contact_us")}</Link></RegularText>
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </div>
      </Card>
    </BlockStack>
  );
};

export default CheckBox;