import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { BlockStack, Card, Collapsible } from '@shopify/polaris';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface FAQItem {
  label: string;
  extend: boolean;
  content: React.ReactNode;
  mixpanelEvent: string;
}

const FAQ: FC = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const initialData: FAQItem[] = useMemo(
    () => [
      {
        label: t('does_this_app_cost_me_monthly'),
        extend: false,
        content: (
          <RegularText>
            <Trans t={t} i18nKey={'no_app_free_customize_popup'}>
              No, the app is completely <b>FREE</b>, and you can customize the pop-up without any limitations.
            </Trans>
          </RegularText >
        ),
        mixpanelEvent: 'First_question_faq',
      },
      {
        label: t('is_app_compatible_with_shopify_themes'),
        extend: false,
        content: (
          <>
            <RegularText>{t('yes_app_works_seamlessly_with_shopify_themes')}</RegularText>
          </>
        ),
        mixpanelEvent: 'Second_question_faq',
      },
      {
        label: t('is_app_compatible_with_custom_themes'),
        extend: false,
        content: (
          <RegularText>
            {t('custom_theme_css_issues')}
          </RegularText>
        ),
        mixpanelEvent: 'Third_question_faq',
      },
    ],
    [t],
  );

  const [listData, setListData] = useState<FAQItem[]>([]);

  useEffect(() => {
    setListData(initialData);
  }, [initialData]);

  const handleToggle = (index: number) => {
    setListData((prevData) =>
      prevData.map((item, i) => {
        if (i === index) {
          return { ...item, extend: !item.extend };
        }
        return { ...item, extend: false };
      }),
    );
  };

  return (
    <Card padding={{ xs: '400', lg: '400' }}>
      <div className="mb-16">
        <BoldText>{t('frequently_ask_questions')}</BoldText>
      </div>
      <BlockStack gap="500">
        {listData.map((question, index) => (
          <div className="faq-item-container" key={question.label}>
            <Card>
              <div className="faq-item-label" onClick={() => handleToggle(index)}>
                {question.label}
              </div>
            </Card>
            {/* <div className="faq-extend-status">
              <Icon source={!question.extend ? PlusIcon : MinusIcon} />
            </div> */}

            <Collapsible
              open={question.extend}
              id="basic-collapsible"
              transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
            >
              <div className="faq-content">
                <RegularText>{question.content}</RegularText>
              </div>
            </Collapsible>
          </div>
        ))}
      </BlockStack>
    </Card>
  );
};

export default FAQ;
