const search = window.location.search.substring(1);
const convertPathname = (pathname: string) => {
  return {
    pathname: `${pathname ? '/' + pathname : ''}`,
    search: '?' + search,
  };
};

export const PATH = {
  DEFAULT: convertPathname('dashboard'),
  POP_UP_CUSTOMIZATION: convertPathname('popup-customization'),
  CUSTOM_TEMPLATE: convertPathname('custom-template'),
  SETTINGS: convertPathname('settings'),
  CHECKOUT_VERIFICATION: convertPathname('checkout_verification'),
  CHECKOUT_VERIFICATION_SETUP: convertPathname('checkout_verification/setup'),
  TRANSLATION: convertPathname('translation'),
  TRANSLATION_SETUP: convertPathname('translation/setup'),
  TERM_AND_CONDITION: convertPathname('terms-and-conditions'),
  TERM_AND_CONDITION_SETUP: convertPathname('terms-and-conditions/setup')
};
