import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import { config } from '@/config';
import { ApplyRule, Embedded, Month } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation, isSubArray } from '@/helpers';
import TextFieldTranslation from '@/pages/Translation/components/TextFieldTranslation';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import toastSlice from '@/redux/slice/Toast/toast.slice';
import useHandleContextual from '@/components/ContextualBar/HideContextual';
import {
  handleCustomizeTranslation,
  initialState,
  selectRuleInfoSelector,
  selectShopLocaleSelector,
} from '@/redux/slice/translation.slice';
import {
  Autocomplete,
  Badge,
  BlockStack,
  Button,
  Card,
  Collapsible,
  Divider,
  Icon,
  InlineGrid,
  Select,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import { PlusIcon, SearchIcon } from '@shopify/polaris-icons';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TranslationStyled } from './styled';
import { PATH } from '@/constants/path';
import { IResponseApi } from '@/types/apis/response';
import { ITranslation } from '@/types/components/translation';
import { useTranslation } from 'react-i18next';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';

const DEFAULT_TRANSLATION: ITranslation = {
  headingText: '',
  subHeadingText: '',
  submitButtonLabel: '',
  cancelButtonLabel: '',
  monthsLabel: {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: '',
  },
  errorMessage: '',
};

const DEFAULT_MONTH_LABEL = {
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
};

function TranslationSetup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { smUp } = useBreakpoints();
  const { language, id } = useParams();
  const contextual = useHandleContextual();
  const { t } = useTranslation(["translation", "common"]);
  const isSkipApi = useSelector(isSkipApiSelector);
  const locales = useSelector(selectShopLocaleSelector);
  const ruleInfo = useSelector(selectRuleInfoSelector);
  const isShowContextualBar = useSelector(isShowSelector);
  const [fetchLanguages] = apiCaller.useLazyGetLanguageQuery();
  const { data: monthLabelRaw } = apiCaller.useGetMonthLabelQuery(undefined, { skip: isSkipApi });

  const ruleOptions = useMemo(
    () => [{ label: t('all_rule'), value: '0' }, ...ruleInfo.map((item) => ({ label: item.name, value: item.id.toString() }))],
    [ruleInfo, t],
  );

  const { data: singleTranslation, isLoading: loading } = apiCaller.useGetSingleTranslationQuery(
    { id: id },
    { skip: isSkipApi || id === 'add' },
  );

  const [getRuleName] = apiCaller.useLazyGetRuleNameQuery();
  const [getPreviewLabel, { isLoading: loadingPreview }] = apiCaller.useLazyGetPreviewLabelQuery();

  const [selectedRule, setSelectedRule] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [displayRule, setDisplayRule] = useState<{ label: string; value: string }[]>(ruleOptions);
  const [errorPopup, setErrorPopup] = useState<string>('');
  const [isFirstLoad, setIsFristLoad] = useState<boolean>(false);

  const [translationValue, setTranslationValue] = useState<IResponseApi.ISingleTranslation>({
    id: 0,
    locale: language ?? '',
    applyRule: ApplyRule.SPECIFIC,
    ruleIdsApply: [],
    status: 1,
    translations: DEFAULT_TRANSLATION,
  });

  const preTranslationValue = useRef({ ...translationValue });

  const [previewTranslationValue, setPreviewTranslationValue] = useState<ITranslation | null>({
    headingText: '',
    monthsLabel: DEFAULT_MONTH_LABEL,
    cancelButtonLabel: '',
    errorMessage: '',
    subHeadingText: '',
    submitButtonLabel: '',
  });

  const translationByLanguage = singleTranslation?.data.translations;
  const isShowPreview =
    translationValue.applyRule === ApplyRule.SPECIFIC && translationValue.ruleIdsApply.length === 1 && previewTranslationValue;

  const languageTitle = useMemo(() => {
    return locales?.find((item) => item.locale === language)?.name ?? '';
  }, [language, locales]);

  const translateMonth = useMemo(() => {
    return [
      {
        id: Month.January.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.january || t('January'),
        currentLabel: translationValue.translations?.monthsLabel?.january,
        status: translationByLanguage?.monthsLabel?.january !== '' && translationByLanguage?.monthsLabel?.january !== undefined,
      },
      {
        id: Month.February.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.february || t('February'),
        currentLabel: translationValue.translations?.monthsLabel?.february,
        status: translationByLanguage?.monthsLabel?.february !== '' && translationByLanguage?.monthsLabel?.february !== undefined,
      },
      {
        id: Month.March.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.march || t('March'),
        currentLabel: translationValue.translations?.monthsLabel?.march,
        status: translationByLanguage?.monthsLabel?.march !== '' && translationByLanguage?.monthsLabel?.march !== undefined,
      },
      {
        id: Month.April.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.april || t('April'),
        currentLabel: translationValue.translations?.monthsLabel?.april,
        status: translationByLanguage?.monthsLabel?.april !== '' && translationByLanguage?.monthsLabel?.april !== undefined,
      },
      {
        id: Month.May.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.may || t('May'),
        currentLabel: translationValue.translations?.monthsLabel?.may,
        status: translationByLanguage?.monthsLabel?.may !== '' && translationByLanguage?.monthsLabel?.may !== undefined,
      },
      {
        id: Month.June.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.june || t('June'),
        currentLabel: translationValue.translations?.monthsLabel?.june,
        status: translationByLanguage?.monthsLabel?.june !== '' && translationByLanguage?.monthsLabel?.june !== undefined,
      },
      {
        id: Month.July.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.july || t('July'),
        currentLabel: translationValue.translations?.monthsLabel?.july,
        status: translationByLanguage?.monthsLabel?.july !== '' && translationByLanguage?.monthsLabel?.july !== undefined,
      },
      {
        id: Month.August.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.august || t('August'),
        currentLabel: translationValue.translations?.monthsLabel?.august,
        status: translationByLanguage?.monthsLabel?.august !== '' && translationByLanguage?.monthsLabel?.august !== undefined,
      },
      {
        id: Month.September.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.september || t('September'),
        currentLabel: translationValue.translations?.monthsLabel?.september,
        status:
          translationByLanguage?.monthsLabel?.september !== '' && translationByLanguage?.monthsLabel?.september !== undefined,
      },
      {
        id: Month.October.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.october || t('October'),
        currentLabel: translationValue.translations?.monthsLabel?.october,
        status: translationByLanguage?.monthsLabel?.october !== '' && translationByLanguage?.monthsLabel?.october !== undefined,
      },
      {
        id: Month.November.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.november || t('November'),
        currentLabel: translationValue.translations?.monthsLabel?.november,
        status: translationByLanguage?.monthsLabel?.november !== '' && translationByLanguage?.monthsLabel?.november !== undefined,
      },
      {
        id: Month.December.toLocaleLowerCase(),
        previewLabel: previewTranslationValue?.monthsLabel?.december || t('December'),
        currentLabel: translationValue.translations?.monthsLabel?.december,
        status: translationByLanguage?.monthsLabel?.december !== '' && translationByLanguage?.monthsLabel?.december !== undefined,
      },
    ];
  }, [translationValue, translationByLanguage, previewTranslationValue, t]);
  const labelMonths = translateMonth.filter((month) => month.status === true);
  const nonLabelMonths = translateMonth.filter((month) => month.status === false);
  const [activeLabelMonths, setActiveLabelMonths] = useState<string[]>([]);

  const handleAddMonth = () => {
    if (nonLabelMonths.length > 0 && activeLabelMonths.length < nonLabelMonths.length) {
      setActiveLabelMonths((prev) => [...prev, nonLabelMonths[activeLabelMonths.length].id]);
    }
  };
  const handleAddFromFebruary = () => {
    setIsOpenMonthLabel(true);
    setActiveLabelMonths(['january']);
    if (activeLabelMonths.length < nonLabelMonths.length) {
      const nextMonthIndex = activeLabelMonths.length + 1;
      setActiveLabelMonths((prev) => [...prev, nonLabelMonths[nextMonthIndex].id]);
    }
  };

  const [updateTranslation, { isLoading }] = apiCaller.useUpdateTranslationMutation();
  const [isOpenMonthLabel, setIsOpenMonthLabel] = useState(false);
  const toggleCollapsible = () => {
    setIsOpenMonthLabel(!isOpenMonthLabel);
  };

  const handleTranslationChange = (key: keyof ITranslation) => (value: string) => {
    if (key !== 'monthsLabel') {
      setTranslationValue((pre) => {
        if (pre.translations) {
          return { ...pre, translations: { ...pre.translations, [key]: value } };
        }
        return pre;
      });
    }
  };

  const handleMonthLabelChange = (month: string) => (value: string) => {
    setTranslationValue((pre) => {
      if (pre.translations?.monthsLabel) {
        return {
          ...pre,
          translations: {
            ...pre.translations,
            monthsLabel: {
              ...pre.translations.monthsLabel,
              [month]: value,
            },
          },
        };
      }
      return pre;
    });
  };

  const handleSaveTranslation = () => {
    updateTranslation({
      ...translationValue,
      status: Boolean(translationValue.status),
      ruleIdsApply: translationValue.ruleIdsApply ?? [],
    }).then((res: any) => {
      if ('data' in res) {
        preTranslationValue.current = { ...translationValue };
        setIsOpenMonthLabel(false);
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        contextual.hide();
        navigate(PATH.TRANSLATION);
      } else {
        const isError = checkShowErrorInline(res);
        if (isError.message.includes('already exists')) {
          setErrorPopup(isError.message);
        } else {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        }
        setIsOpenMonthLabel(false);
      }
    });
  };

  const handleBackPage = useCallback(() => {
    if (isShowContextualBar && config.embedded === Embedded.LIVE) {
      contextual.hide();
    } else {
      navigate(PATH.TRANSLATION);
    }
  }, [isShowContextualBar, contextual, navigate]);

  const isDataEqual = useMemo(() => {
    const { translations, ...currentVal } = translationValue;
    const { translations: translationsRef, ...currentValRef } = preTranslationValue.current;
    return isEqual(translations, translationsRef) && isEqual(currentVal, currentValRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationValue, preTranslationValue.current]);

  const handleDiscardChanges = () => {
    setTranslationValue({ ...preTranslationValue.current });
    const ruleIdExist = ruleInfo?.map((item) => item.id) ?? [];
    setSelectedRule(
      preTranslationValue.current.ruleIdsApply
        .filter((val) => !ruleIdExist.length || ruleIdExist.includes(val))
        .map((item) => item.toString()),
    );
  };

  const handleSelectRule = useCallback(
    (value: string[]) => {
      if (value.includes('0') && !selectedRule.includes('0')) {
        setSelectedRule(ruleOptions.map((item) => item.value));
        setDisplayRule(ruleOptions);
        setTranslationValue((pre) => ({ ...pre, ruleIdsApply: ruleInfo.map((val) => val.id) }));
      } else if (value.includes('0') && selectedRule.includes('0')) {
        setSelectedRule(value.filter((item) => item !== '0'));
        setTranslationValue((pre) => ({
          ...pre,
          ruleIdsApply: value.filter((item) => item !== '0').map((val) => parseInt(val)),
        }));
      } else if (!selectedRule.includes('0') && value.length === ruleInfo.length) {
        setSelectedRule(ruleOptions.map((item) => item.value));
        setDisplayRule(ruleOptions);
        setTranslationValue((pre) => ({ ...pre, ruleIdsApply: ruleInfo.map((val) => val.id) }));
      } else if (selectedRule.includes('0') && !value.includes('0')) {
        setSelectedRule([]);
        setTranslationValue((pre) => ({ ...pre, ruleIdsApply: [] }));
      } else {
        setTranslationValue((pre) => ({ ...pre, ruleIdsApply: value.map((val) => parseInt(val)) }));
        setSelectedRule(value);
      }
      if (!value.length) {
        setErrorPopup(t('pop_up_required'));
      } else {
        if (errorPopup.length) {
          setErrorPopup('');
        }
      }
    },
    [ruleInfo, ruleOptions, selectedRule, errorPopup, t],
  );

  const handleChangeSearchText = useCallback(
    (value: string) => {
      setSearchText(value);
      if (value.length === 0) {
        setDisplayRule(ruleOptions);
      } else {
        setDisplayRule(ruleOptions.filter((item) => item.label.toLowerCase().includes(value.toLowerCase())));
      }
    },
    [ruleOptions],
  );

  const handleBlueInput = useCallback(() => {
    if (!selectedRule.length) {
      setErrorPopup(t('pop_up_required'));
    } else {
      setErrorPopup('');
    }
  }, [selectedRule, t]);

  const handleGetPrivewLabel = async (id: string) => {
    try {
      const res = await getPreviewLabel({ id });
      if (res.data?.label) {
        setPreviewTranslationValue((pre) => ({ ...res.data?.label, monthsLabel: { ...pre?.monthsLabel } }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ruleSelectLabel = useMemo(() => {
    return selectedRule.length
      ? selectedRule.includes('0')
        ? t('all_pop_up')
        : selectedRule
          .map((val) => displayRule?.find((item) => item.value === val)?.label)
          .filter((v) => !!v)
          .join(',')
      : t('select_pop_up');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule]);

  const translationLayoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
        primaryAction: {
          content: t('common:save'),
          onAction: handleSaveTranslation,
          disabled: isDataEqual,
          loading: isLoading,
        },
        secondaryActions: (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button disabled={isDataEqual} onClick={handleDiscardChanges}>
              {t('common:discard')}
            </Button>
          </div>
        ),
      }
      : null;

  useEffect(() => {
    if (singleTranslation?.data.translations) {
      const translationValue = singleTranslation?.data.translations;
      const updatedTranslationDetail = {
        headingText: translationValue?.headingText || '',
        subHeadingText: translationValue?.subHeadingText || '',
        submitButtonLabel: translationValue?.submitButtonLabel || '',
        cancelButtonLabel: translationValue?.cancelButtonLabel || '',
        monthsLabel: translationValue?.monthsLabel || {
          january: '',
          february: '',
          march: '',
          april: '',
          may: '',
          june: '',
          july: '',
          august: '',
          september: '',
          october: '',
          november: '',
          december: '',
        },
        errorMessage: translationValue?.errorMessage || '',
      };
      dispatch(handleCustomizeTranslation(updatedTranslationDetail));
    } else {
      dispatch(handleCustomizeTranslation(initialState.translation));
    }
    // eslint-disable-next-line
  }, [singleTranslation, dispatch]);

  useEffect(() => {
    if (!locales || !locales.length) {
      fetchLanguages({ shop: config.shop });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locales]);

  useEffect(() => {
    if ((!ruleInfo || !ruleInfo.length) && !isFirstLoad) {
      getRuleName();
      setIsFristLoad(true);
    } else {
      setDisplayRule([
        { label: 'All rule', value: '0' },
        ...ruleInfo.map((item) => ({ label: item.name, value: item.id.toString() })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleInfo]);

  useEffect(() => {
    if (monthLabelRaw?.monthsLabel) {
      setPreviewTranslationValue((pre) =>
        pre
          ? { ...pre, monthsLabel: { ...pre.monthsLabel, ...monthLabelRaw.monthsLabel } }
          : { monthsLabel: { ...monthLabelRaw.monthsLabel } },
      );
    }
  }, [monthLabelRaw]);

  useEffect(() => {
    if (!selectedRule.includes('0') && selectedRule.length === 1) {
      handleGetPrivewLabel(selectedRule[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule]);

  useEffect(() => {
    if (singleTranslation?.data) {
      const ruleIdExist = ruleInfo?.map((item) => item.id) ?? [];
      const listRuleNew =
        singleTranslation.data.ruleIdsApply?.filter((val) => !ruleIdExist.length || ruleIdExist.includes(val)) ?? [];
      setTranslationValue(() => ({
        ...singleTranslation?.data,
        ruleIdsApply: listRuleNew,
        translations: {
          ...singleTranslation?.data.translations,
          monthsLabel: singleTranslation?.data.translations?.monthsLabel ?? DEFAULT_TRANSLATION.monthsLabel,
        },
      }));
      preTranslationValue.current = {
        ...singleTranslation?.data,
        ruleIdsApply: listRuleNew,
        translations: {
          ...singleTranslation?.data.translations,
          monthsLabel: singleTranslation?.data.translations?.monthsLabel ?? DEFAULT_TRANSLATION.monthsLabel,
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { monthsLabel, ...data } = singleTranslation?.preview ?? {};
      if (data) {
        setPreviewTranslationValue((pre) => (pre ? { ...pre, ...data } : pre));
      }
      if (isSubArray(singleTranslation?.data?.ruleIdsApply ?? [], ruleIdExist)) {
        setSelectedRule(['0', ...ruleOptions.map((item) => item.value)]);
      } else {
        setSelectedRule([...(singleTranslation?.data.ruleIdsApply?.length ? listRuleNew.map((item) => item.toString()) : [])]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Layout
      title={languageTitle}
      titleMetadata={translationValue.status ? <Badge tone="success">{t('published')}</Badge> : <Badge>{t('unpublished')}</Badge>}
      subtitle={t('translate_widgets_multiple_languages')}
      backAction={{
        content: t('goback'),
        onAction: handleBackPage,
      }}
      {...translationLayoutSettings}
    >
      <TranslationStyled>
        {config.embedded === Embedded.LIVE && (
          <ContextualBar
            delay={state?.prePath}
            value={translationValue}
            loadingSave={isLoading}
            disabledDiscard={isDataEqual || loading}
            disabledSave={
              isDataEqual ||
              loading ||
              (translationValue.applyRule === ApplyRule.SPECIFIC && !translationValue.ruleIdsApply.length)
            }
            onDiscard={handleDiscardChanges}
            onSave={handleSaveTranslation}
          />
        )}
        <div className="mt-16">
          <BlockStack gap={{ xs: '800', sm: '400' }}>
            <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
              <BlockStack gap={'400'}>
                <Text as="h3" variant="headingMd">
                  {t('infor')}
                </Text>
                <Text as="p" variant="bodyMd" tone="subdued">
                  {t('select_pop_op_translation_display')}.
                </Text>
              </BlockStack>
              <Card padding="500">
                <BlockStack gap="400">
                  <Select
                    label={t('common:status')}
                    options={[
                      {
                        label: t('published'),
                        value: '1',
                      },
                      {
                        label: t('unpublished'),
                        value: '0',
                      },
                    ]}
                    value={translationValue.status ? '1' : '0'}
                    helpText={t('translation_status') + "."}
                    onChange={() => setTranslationValue((preVal) => ({ ...preVal, status: translationValue.status ? 0 : 1 }))}
                  />
                  <Autocomplete
                    options={displayRule}
                    selected={selectedRule}
                    allowMultiple
                    onSelect={handleSelectRule}
                    textField={
                      <Autocomplete.TextField
                        prefix={<Icon source={SearchIcon} />}
                        placeholder={ruleSelectLabel}
                        label={t('pop_up')}
                        value={searchText}
                        autoComplete="off"
                        onChange={handleChangeSearchText}
                        error={errorPopup}
                        onBlur={handleBlueInput}
                        loading={loading}
                        requiredIndicator
                      />
                    }
                  />
                </BlockStack>
              </Card>
            </InlineGrid>
            <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  {t('text')}
                </Text>
                <Text as="p" variant="bodyMd" tone="subdued">
                  {t('customize_input_field_text_translation_form')}
                </Text>
              </BlockStack>
              <Card padding="500">
                <BlockStack gap="400">
                  <TextFieldTranslation
                    label={t('heading_text')}
                    defaultValue={!isShowPreview ? '' : previewTranslationValue.headingText}
                    onChange={handleTranslationChange('headingText')}
                    value={translationValue.translations?.headingText || ''}
                    loadingPreview={loadingPreview}
                    loading={loading}
                  />
                  <TextFieldTranslation
                    label={t('sub_heading_text')}
                    defaultValue={!isShowPreview ? '' : previewTranslationValue.subHeadingText}
                    onChange={handleTranslationChange('subHeadingText')}
                    value={translationValue.translations?.subHeadingText || ''}
                    loadingPreview={loadingPreview}
                    loading={loading}
                  />
                </BlockStack>
              </Card>
            </InlineGrid>
            {smUp && <Divider />}
            <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  {t('button')}
                </Text>
                <Text as="p" variant="bodyMd" tone="subdued">
                  {t('customize_labels_input_fields_buttons')}
                </Text>
              </BlockStack>
              <Card padding="500">
                <BlockStack gap="400">
                  <TextFieldTranslation
                    label={t('submit_button_label')}
                    defaultValue={!isShowPreview ? '' : previewTranslationValue.submitButtonLabel}
                    onChange={handleTranslationChange('submitButtonLabel')}
                    value={translationValue.translations?.submitButtonLabel || ''}
                    loadingPreview={loadingPreview}
                    loading={loading}
                  />
                  <TextFieldTranslation
                    label={t('cancel_button_label')}
                    defaultValue={!isShowPreview ? '' : previewTranslationValue.cancelButtonLabel}
                    onChange={handleTranslationChange('cancelButtonLabel')}
                    value={translationValue.translations?.cancelButtonLabel || ''}
                    loadingPreview={loadingPreview}
                    loading={loading}
                  />
                  <TextFieldTranslation
                    label={t('validation_date_error_message')}
                    defaultValue={!isShowPreview ? '' : previewTranslationValue.errorMessage}
                    onChange={handleTranslationChange('errorMessage')}
                    value={translationValue.translations?.errorMessage || ''}
                    loadingPreview={loadingPreview}
                    loading={loading}
                  />
                </BlockStack>
              </Card>
            </InlineGrid>
            {smUp && <Divider />}
            {
              <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    {t('label')}
                  </Text>
                  <Text as="p" variant="bodyMd" tone="subdued">
                    {t('customize_month_labels')}
                  </Text>
                </BlockStack>
                <Card padding="500">
                  <BlockStack gap="400">
                    {labelMonths.map((month, index) => {
                      return (
                        <Collapsible
                          key={month.id}
                          open={isOpenMonthLabel || index === 0}
                          id="basic-collapsible"
                          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                          expandOnPrint
                        >
                          <TextFieldTranslation
                            label={t('month_label')}
                            defaultValue={month.previewLabel}
                            onChange={handleMonthLabelChange(month.id)}
                            value={month.currentLabel || ''}
                            loadingPreview={loadingPreview}
                            loading={loading}
                          />
                        </Collapsible>
                      );
                    })}
                    {activeLabelMonths.length === 0 && labelMonths.length === 0 ? (
                      <>
                        <TextFieldTranslation
                          key={nonLabelMonths[0].id}
                          label={t('month_label')}
                          defaultValue={nonLabelMonths[0].previewLabel}
                          onChange={handleMonthLabelChange(nonLabelMonths[0].id)}
                          value={nonLabelMonths[0].currentLabel || ''}
                          loadingPreview={loadingPreview}
                          loading={loading}
                        />
                        <div>
                          <Button icon={PlusIcon} onClick={handleAddFromFebruary}>
                            {t('common:add')}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {activeLabelMonths.map((monthId) => {
                          const month = nonLabelMonths.find((m) => m.id === monthId);
                          return (
                            month && (
                              <TextFieldTranslation
                                key={month.id}
                                label={t('month_label')}
                                defaultValue={month.previewLabel}
                                onChange={handleMonthLabelChange(month.id)}
                                value={month.currentLabel || ''}
                                loadingPreview={loadingPreview}
                                loading={loading}
                              />
                            )
                          );
                        })}
                      </>
                    )}
                    {((activeLabelMonths.length < nonLabelMonths.length && isOpenMonthLabel) || labelMonths.length === 1) && (
                      <div>
                        <Button icon={PlusIcon} onClick={handleAddMonth}>
                          {t('common:add')}
                        </Button>
                      </div>
                    )}
                  </BlockStack>
                  {labelMonths.length > 1 && (
                    <div className="translate-btn-collapse">
                      <Button onClick={toggleCollapsible}>{isOpenMonthLabel ? t('see_less') : t('see_more')}</Button>
                    </div>
                  )}
                </Card>
              </InlineGrid>
            }
          </BlockStack>
        </div>
      </TranslationStyled>
    </Layout>
  );
}

export default TranslationSetup;
