import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import Layout from '@/components/layout';
import { CheckoutVerifyType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import {
  BannerStyleType,
  clearAllStateCheckoutVerify,
  handleChangeVerifyType,
  handlePushDataCheckoutVerify,
  setupVerifySelector,
} from '@/redux/slice/checkoutVerification.slice';
import { Badge, BlockStack, Button, Checkbox, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bannerStyles } from '../CheckoutVerificationSetup/components/BannerVerify';
import { DateOrderVerify } from '../CheckoutVerificationSetup/components/PreviewCheckout';
import { CheckoutVerificationStyled } from './styled';
import { useTranslation } from 'react-i18next';

const CheckoutVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopifyPlanPlus } = UserPlans();
  const { t } = useTranslation(["checkout", "common"]);

  const setupVerify = useSelector(setupVerifySelector);
  const { bannerActive, validateInputActive } = setupVerify;

  const isSkip = useSelector(isSkipApiSelector);

  const { data, isLoading } = apiCaller.useGetSetupCheckoutVerifyQuery(undefined, {
    skip: isSkip,
  });

  const listBanner = bannerStyles
    .filter((banner) => banner.value !== BannerStyleType.Info)
    .map((banner) => ({
      ...banner,
      heading:
        banner.value === BannerStyleType.Warning
          ? t('verify_your_age')
          : banner.value === BannerStyleType.Success
            ? t('youre_over_18')
            : t('you_must_at_least_18'),
    }));

  useEffect(() => {
    if (data?.setting) {
      dispatch(handlePushDataCheckoutVerify(data.setting));
    } else {
      dispatch(clearAllStateCheckoutVerify());
    }
  }, [data, dispatch]);

  return (
    <Layout title={t('checkout_verification')}>
      <CheckoutVerificationStyled>
        <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
          <div className="card-item">
            <div className="card-item-top">
              <BlockStack gap="400">
                {listBanner.map((banner) => (
                  <BannerCheckoutUI key={banner.value} banner={banner} heading={banner.heading} subHeading=" " />
                ))}
              </BlockStack>
            </div>
            <div className="card-item-bottom">
              <Text as="h4" variant="headingMd">
                {t('infor_banner')}
              </Text>
              <Text as="h5" tone="subdued">
                {t('checkout_banner_age_restriction')}
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  onClick={() => {
                    dispatch(handleChangeVerifyType(CheckoutVerifyType.InfoBanner));
                    // if (!data?.setting || (data?.setting && !data?.setting?.firstActive?.includes('banner'))) {
                    //   dispatch(
                    //     handlePushDataCheckoutVerify({
                    //       ...setupVerify,
                    //       bannerActive: true,
                    //     }),
                    //   );
                    // }
                    navigate(PATH.CHECKOUT_VERIFICATION_SETUP);
                  }}
                >
                  {t('customize')}
                </Button>
                {!isLoading && (
                  <Badge tone={data?.setting ? (bannerActive ? 'success' : 'new') : 'new'}>
                    {data?.setting ? (bannerActive ? t('common:enabled') : t('common:disabled')) : t('common:disabled')}
                  </Badge>
                )}
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-item-top">
              <BlockStack gap="600">
                <InlineStack align="space-around" blockAlign="center">
                  <Badge tone="critical">18+</Badge>
                  <div className="wrap-checkbox">
                    <Checkbox label={t('im_over_18_years_old')} />
                  </div>
                </InlineStack>
                <BlockStack gap="200">
                  <Text as="h4" variant="headingMd" tone="subdued">
                    {t('enter_your_date_of_birth')}
                  </Text>
                  <DateOrderVerify />
                  <Text as="h4" tone="critical">
                    {t('you_must_over_18')}
                  </Text>
                </BlockStack>
              </BlockStack>
            </div>
            <div className="card-item-bottom">
              <InlineStack gap="200" blockAlign="center">
                <Text as="h4" variant="headingMd">
                  {t('checkout_verification')}
                </Text>
                <Badge tone="info">Shopify Plus</Badge>
              </InlineStack>
              <Text as="h5" tone="subdued">
                {t('verify_age_checkout')}
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  disabled={!shopifyPlanPlus}
                  onClick={() => {
                    dispatch(handleChangeVerifyType(CheckoutVerifyType.CheckboxOrDate));
                    // if (!data?.setting || (data?.setting && !data?.setting?.firstActive?.includes('validate'))) {
                    //   dispatch(
                    //     handlePushDataCheckoutVerify({
                    //       ...setupVerify,
                    //       validateInputActive: true,
                    //     }),
                    //   );
                    // }
                    navigate(PATH.CHECKOUT_VERIFICATION_SETUP);
                  }}
                >
                  {t('customize')}
                </Button>
                {!isLoading && (
                  <Badge tone={validateInputActive && shopifyPlanPlus ? 'success' : 'new'}>
                    {validateInputActive && shopifyPlanPlus ? t('common:enabled') : t('common:disabled')}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </InlineGrid>
      </CheckoutVerificationStyled>
    </Layout>
  );
};

export default CheckoutVerification;
