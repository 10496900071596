import { images } from '@/asset';
import { OpacityContainer } from '@/components/OpacityContainer';
import SkeletonCustom from '@/components/SkeletonCustom';
import { DeviceType, TermsType } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { shopSelector, showWatermarkSelector } from '@/redux/slice/shop.slice';
import { setupTermAndConditionSelector, termAndConditionTypeSelector } from '@/redux/slice/termAndCondition.slice';
import { BlockStack, Box, Card, Checkbox, Icon, InlineGrid, InlineStack, Link, SkeletonDisplayText } from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PreviewTermAndConditionStyled } from './styled';

const PreviewTermAndCondition = () => {
  const { t } = useTranslation("term-condition");
  const showWatermark = useSelector(showWatermarkSelector);
  const setupTermAndCondition = useSelector(setupTermAndConditionSelector);
  const termAndConditionType = useSelector(termAndConditionTypeSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { email } = useSelector(shopSelector);
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip || email?.length > 0 });
  const [selectedDevice, setSelectedDevice] = useState(DeviceType.DESKTOP);
  const isSelectMobile = selectedDevice === DeviceType.MOBILE;
  const [isChecked, setIsChecked] = useState(false);

  const removeWaterMark = () => {
    const sendText = 'I want to remove brandmark in the verification page';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
  };

  const renderMessageContent = () => {
    const { messageContent, keyword, link } = setupTermAndCondition;

    // Sử dụng giá trị mặc định nếu messageContent hoặc keyword là null, và loại bỏ khoảng trắng đầu cuối
    const displayMessageContent = (messageContent ?? t('terms_agreement_text_field')).trim();
    const displayKeyword = (keyword ?? t('terms_conditions_default')).trim();

    // Nếu không có giá trị hợp lệ, trả về nội dung mặc định
    if (!displayMessageContent || !displayKeyword || !link) {
      return <span>{displayMessageContent}</span>;
    }

    // Tìm vị trí của keyword trong messageContent (không phân biệt hoa thường)
    const keywordIndex = displayMessageContent.toLowerCase().indexOf(displayKeyword.toLowerCase());
    if (keywordIndex === -1) {
      return <span>{displayMessageContent}</span>;
    }

    // Cắt chuỗi để hiển thị trước, từ khóa (link), và sau từ khóa
    const beforeKeyword = displayMessageContent.substring(0, keywordIndex);
    const afterKeyword = displayMessageContent.substring(keywordIndex + displayKeyword.length);

    return (
      <div className="pointer-events-none">
        {beforeKeyword}
        <Link>{displayKeyword}</Link>
        {afterKeyword}
      </div>
    );
  };

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked);
  };

  return (
    <PreviewTermAndConditionStyled selectedDevice={selectedDevice} className="preview-checkout">
      <Box paddingBlock="200">
        <InlineStack align="center" blockAlign="center" gap="200">
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.DESKTOP ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.DESKTOP)}
          >
            <Icon source={DesktopIcon} />
          </div>
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.MOBILE ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.MOBILE)}
          >
            <Icon source={MobileIcon} />
          </div>
        </InlineStack>
      </Box>
      <div className='card-container'>
        <Card>
          <InlineGrid columns={isSelectMobile ? 1 : ['oneThird', 'twoThirds']} gap="400">
            <BlockStack gap={"400"}>
              <div className='thumbnail'></div>
              <SkeletonDisplayText size="small" maxWidth={`${100}%`} />
              <SkeletonDisplayText size="small" maxWidth={`${100}%`} />
            </BlockStack>

            <BlockStack gap={"400"}>
              <OpacityContainer>
                <SkeletonDisplayText size="small" maxWidth={`${85}%`} />
              </OpacityContainer>
              <OpacityContainer>
                <SkeletonDisplayText size="small" maxWidth={`${100}%`} />
              </OpacityContainer>
              {termAndConditionType === TermsType.CHECKOUT ? (
                <div>
                  <InlineStack blockAlign="start" gap="200" wrap={false}>
                    <div className="checkbox-input"></div>
                    <p>
                      {t('terms_agreement')}
                    </p>
                  </InlineStack>
                </div>
              ) : (
                <div>
                  <InlineStack blockAlign="center" wrap={false} >
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      label={undefined}
                    />
                    <p
                      style={{
                        fontSize: setupTermAndCondition.messageSize
                          ? `${setupTermAndCondition.messageSize}px`
                          : 'inherit',
                        color: setupTermAndCondition.messageColor || 'inherit',
                      }}
                    >
                      {renderMessageContent()}
                    </p>
                  </InlineStack>
                  {!isChecked && setupTermAndCondition.messageError && (
                    <p style={{ color: "#DD1D1D" }}>
                      {setupTermAndCondition.messageError}
                    </p>
                  )}
                </div>
              )}
              {!fetchDataSettings.isLoading && showWatermark && termAndConditionType === TermsType.STOREFRONT ? (
                <div className="watermark-container">
                  <div className="d-flex">
                    <div>{t("protected_by")}</div>
                    <img className="ml-4" src={images.logo} alt="blockify" />
                    <div
                      onClick={() =>
                        window.open(
                          'http://blockifyapp.com/',
                          '_blank',
                        )
                      }
                      className="watermark-link"
                    >
                      Blockify™
                    </div>
                  </div>
                </div>
              ) : null}
              <OpacityContainer>
                <SkeletonDisplayText size="small" maxWidth={`${85}%`} />
              </OpacityContainer>
              <OpacityContainer>
                <SkeletonDisplayText size="small" maxWidth={`${100}%`} />
              </OpacityContainer>
              {termAndConditionType === TermsType.CHECKOUT &&
                <SkeletonCustom size="small" maxWidth={`${100}%`} height={'21px'} opacity />
              }
            </BlockStack>
          </InlineGrid>
        </Card>
        {!fetchDataSettings.isLoading && showWatermark ? (
          <>
            <Box paddingBlock="300">
              <InlineStack align="center">
                <Link onClick={removeWaterMark}>{t('click_remove_brandmark')}</Link>
              </InlineStack>
            </Box>
          </>
        ) : null}
      </div>
    </PreviewTermAndConditionStyled>
  );
};

export default PreviewTermAndCondition;
