import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { config } from './config';
const isProduction = process.env.REACT_APP_ENV === 'production';
const i18nKey = 'i18nextLng-' + config.shop;

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'common',
    ns: ['common', 'dashboard', 'popup', 'setting', 'checkout', 'translation', 'term-condition'],
    supportedLngs: ['en', 'vi', 'de', 'hi', 'es', 'fr', 'it'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: i18nKey,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_END_POINT}locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: true,
    },
    saveMissing: !isProduction,
    preload: ['en'],
  });

const savedLang = localStorage.getItem(i18nKey);

if (!savedLang) {
  i18n.changeLanguage('en');
  localStorage.setItem(i18nKey, 'en');
}

export default i18n;
