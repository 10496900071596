import { BlockStack, Button, DropZone, InlineError, InlineStack, Thumbnail } from '@shopify/polaris';
import { NoteIcon, XIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { DropZoneCustomStyled } from './styled';
import { useTranslation } from 'react-i18next';

interface DropZoneCustomProps {
  onChangeFile: (file: File[], value: string) => void;
  label: string;
  onRemove: () => void;
  fileDefault: string;
}

const DropZoneCustom: React.FC<DropZoneCustomProps> = ({ onChangeFile, label, onRemove, fileDefault }) => {
  const [error, setError] = useState('');
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const maxSize = 2000000;

  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      if (acceptedFiles.length > 0 && validImageTypes.includes(acceptedFiles[0].type)) {
        setError('');
        const url = window.URL.createObjectURL(acceptedFiles[0]);
        onChangeFile(acceptedFiles, url);
      }
    },
    [onChangeFile, validImageTypes],
  );

  const validateFile = (file: any) => {
    if (file.size > maxSize) {
      setError(t('file_to_large'));
    }
    if (!validImageTypes.includes(file.type)) {
      setError(t('unsupported'));
    }
    return file.size < maxSize && validImageTypes.includes(file.type);
  };

  return (
    <DropZoneCustomStyled>
      <BlockStack gap="100">
        <div className="dropZone-container">
          <DropZone
            label={label}
            allowMultiple={false}
            type="image"
            customValidator={validateFile}
            onDrop={handleDropZoneDrop}
          // disabled={!!fileDefault}
          >
            {!fileDefault ? <DropZone.FileUpload actionHint={t('accept_file')} /> : null}
          </DropZone>

          {fileDefault && (
            <div className="file-preview">
              <InlineStack gap="400" align="center" blockAlign="center">
                <Thumbnail size="large" alt="preview-file" source={fileDefault || NoteIcon} />
                <Button variant="tertiary" icon={XIcon} onClick={onRemove} />
              </InlineStack>
            </div>
          )}
        </div>

        {!!error ? <InlineError message={error} fieldID="error-drop-zone" /> : null}
      </BlockStack>
    </DropZoneCustomStyled>
  );
};

export default DropZoneCustom;
