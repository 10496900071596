import logo from '@/asset/dashboard/logo.webp';
import emptyState from '@/asset/emptyState.png';
import twitter from '@/asset/dashboard/twitter.png';
import banner_set_up_multiple_pop_up from '@/asset/banner_set_up_multiple_pop_up.png';
import iconInfo from '@/asset/icon/icon-info.png';
import iconWarning from '@/asset/icon/icon-warning.png';
import iconSuccess from '@/asset/icon/icon-success.png';
import iconCritical from '@/asset/icon/icon-critical.png';
import blockify from '@/asset/dashboard/blockify.png';
import term_and_condition_checkout from '@/asset/term_and_condition_checkout.png';
import term_and_condition_product_and_cart from '@/asset/term_and_condition_product_and_cart.png';

export const images = { logo, iconInfo, iconWarning, iconSuccess, iconCritical, emptyState, twitter, banner_set_up_multiple_pop_up, blockify, term_and_condition_checkout, term_and_condition_product_and_cart };
