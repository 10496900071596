import styled from 'styled-components';
import { SkeletonCustomProps } from '.';

export const SkeletonCustomStyled = styled.div<SkeletonCustomProps>`
  opacity: ${(props) => (props.opacity ? 0.5 : 1)};
  .Polaris-SkeletonDisplayText__DisplayText {
    border-radius: 4px;
    height: ${(props) => props?.height};
  }
`;
