import { BackgroundColor, DisplayPages, TermsCondition, TermsType } from '@/constants/enum';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type ISetupTermAndCondition = {
  id: null | undefined | number | string;
  shop: string;
  status: boolean;
  type: TermsType;
  displayPages: DisplayPages[];
  condition: TermsCondition;
  checkBoxSize: string;
  checkBoxColor: string;
  keyword: string | null;
  messageContent: string | null;
  link?: string;
  messageError: string;
  messageSize: string;
  messageColor: string;
  bannerActive?: boolean;
  validateInputActive?: boolean;
};
export type ISetupTermAndConditionCheckoutUI = {
  id: null | undefined | number | string;
  shop: string;
  status: boolean;
  type: TermsType;
  condition: TermsCondition;
};

const setupTermAndCondition: ISetupTermAndCondition = {
  id: null,
  shop: '',
  status: false,
  type: TermsType.CHECKOUT,
  displayPages: [DisplayPages.PRODUCT],
  condition: TermsCondition.ALWAYS,
  checkBoxSize: '20',
  checkBoxColor: BackgroundColor.White,
  messageContent: null,
  keyword: null,
  link: '',
  messageError: '',
  messageSize: '16',
  messageColor: BackgroundColor.Black,
  bannerActive: undefined,
  validateInputActive: undefined,
};

const setupTermAndConditionCheckoutUI: ISetupTermAndConditionCheckoutUI = {
  id: null,
  shop: '',
  status: false,
  type: TermsType.CHECKOUT,
  condition: TermsCondition.ALWAYS,
};

interface TermAndConditionState {
  termAndConditionType: TermsType | null;
  setupTermAndCondition: ISetupTermAndCondition;
  setupTermAndConditionBackup: ISetupTermAndCondition;
  setupTermAndConditionCheckoutUI: ISetupTermAndConditionCheckoutUI;
  setupTermAndConditionCheckoutUIBackup: ISetupTermAndConditionCheckoutUI;
  errors: { [key: string]: string };
}

export const initialStateTermAndCondition: TermAndConditionState = {
  termAndConditionType: null,
  setupTermAndCondition: setupTermAndCondition,
  setupTermAndConditionBackup: setupTermAndCondition,
  setupTermAndConditionCheckoutUI: setupTermAndConditionCheckoutUI,
  setupTermAndConditionCheckoutUIBackup: setupTermAndConditionCheckoutUI,
  errors: {},
};

export const termAndConditionSlice = createSlice({
  name: 'term_and_condition',
  initialState: initialStateTermAndCondition,
  reducers: {
    handleChangeTermAndConditionType: (state, action: PayloadAction<TermsType>) => {
      state.termAndConditionType = action.payload;
    },
    handleChangeSetupTermAndCondition: (state, action: PayloadAction<Partial<ISetupTermAndCondition>>) => {
      state.setupTermAndCondition = {
        ...state.setupTermAndCondition,
        ...action.payload,
      };
    },
    handlePushDataTermAndCondition: (state, action: PayloadAction<ISetupTermAndCondition>) => {
      state.setupTermAndCondition = action.payload;
      state.setupTermAndConditionBackup = action.payload;
    },
    handleChangeSetupTermAndConditionCheckoutUI: (state, action: PayloadAction<Partial<ISetupTermAndConditionCheckoutUI>>) => {
      state.setupTermAndConditionCheckoutUI = {
        ...state.setupTermAndConditionCheckoutUI,
        ...action.payload,
      };
    },
    handlePushDataTermAndConditionCheckoutUI: (state, action: PayloadAction<ISetupTermAndConditionCheckoutUI>) => {
      state.setupTermAndConditionCheckoutUI = action.payload;
      state.setupTermAndConditionCheckoutUIBackup = action.payload;
    },
    clearAllStateTermAndCondition: (state) => {
      state.setupTermAndCondition = setupTermAndCondition;
      state.setupTermAndConditionBackup = setupTermAndCondition;
      state.setupTermAndConditionCheckoutUI = setupTermAndConditionCheckoutUI;
      state.setupTermAndConditionCheckoutUIBackup = setupTermAndConditionCheckoutUI;
    },

    setErrorsTermAndCondition: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.errors = action.payload;
    },
    clearErrorsTermAndCondition: (state) => {
      state.errors = {};
    }
  },
});

export const setupTermAndConditionSelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.setupTermAndCondition,
);

export const setupTermAndConditionBackupSelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.setupTermAndConditionBackup,
);
export const setupTermAndConditionCheckoutUISelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.setupTermAndConditionCheckoutUI,
);

export const setupTermAndConditionCheckoutUIBackupSelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.setupTermAndConditionCheckoutUIBackup,
);

export const termAndConditionTypeSelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.termAndConditionType,
);

export const errorsSelector = createSelector(
  (state: RootState) => state.termAndCondition,
  (state) => state.errors
);
export const {
  clearAllStateTermAndCondition,
  handleChangeSetupTermAndCondition,
  handlePushDataTermAndCondition,
  handleChangeSetupTermAndConditionCheckoutUI,
  handlePushDataTermAndConditionCheckoutUI,
  handleChangeTermAndConditionType,
  setErrorsTermAndCondition,
  clearErrorsTermAndCondition
} = termAndConditionSlice.actions;

export default termAndConditionSlice;
