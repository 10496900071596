import { DeviceType } from '@/constants/enum';
import styled from 'styled-components';
type Props = {
  selectedDevice: DeviceType;
};

export const PreviewTermAndConditionStyled = styled.div<Props>`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  height: fit-content;
  overflow: hidden;

  .card-btn-device {
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  .is-selected {
    background-color: #e3e3e3;
  }

  .Polaris-Icon {
    margin: 0;
  }

  .show-order-summary {
    background: #f5f5f5;
  }
  .card-container {
    width: ${({ selectedDevice }) => (selectedDevice === DeviceType.MOBILE ? '55%' : '95%')};
    margin: 0 auto;
    padding-bottom: 8px;
    .checkout-preview {
      display: flex;
    }
    .watermark-container {
        img {
          margin: 0 4px;
        }
        .watermark-link {
          color: rgba(0, 91, 211, 1);
          text-decoration: underline;
          cursor: pointer;
        }
      }

    .checkbox-input {
      min-width: 16px;
      min-height: 16px;
      border: 1px solid #999;
      border-radius: 3px;
      margin-top: 2px;
    }
    .thumbnail {
      width: 100%;
      border-radius: 10px;
      height: 146px;
      background: #e3e3e3;
    }
  }

  @media screen and (max-width: 768px) {
    .checkout-preview {
      flex-direction: column;

      .checkout-preview-left {
        width: 100%;
        border-right: none;
      }

      .checkout-preview-right {
        width: 100%;
      }
    }
  }
`;
