import { displayPageList, perPageOptions, triggerCondition } from '@/constants/options';
import { handlePopupListTable, popupListTableSelector } from '@/redux/slice/preview.slice';
import { ChoiceList } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const PopupListFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "popup"]);
  const popupListTable = useSelector(popupListTableSelector);
  const filters = [
    {
      key: 'target',
      label: t('target'),
      filter: (
        <ChoiceList
          title={t('target')}
          titleHidden
          choices={[...displayPageList(t)]}
          selected={[popupListTable.pageTypeToShow || '']}
          onChange={(value) =>
            dispatch(
              handlePopupListTable({
                ...popupListTable,
                pageTypeToShow: value[0] || undefined,
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'trigger',
      label: t('trigger'),
      filter: (
        <ChoiceList
          title={t('trigger')}
          titleHidden
          choices={[...triggerCondition(t)]}
          selected={[popupListTable.applyCustomer || '']}
          onChange={(value) =>
            dispatch(
              handlePopupListTable({
                ...popupListTable,
                ...(value[0] !== '' && { applyCustomer: value[0] }),
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: t('per_page'),
      filter: (
        <ChoiceList
          title={t('per_page')}
          titleHidden
          choices={perPageOptions}
          selected={[popupListTable.limit]}
          onChange={(value) =>
            dispatch(
              handlePopupListTable({
                ...popupListTable,
                limit: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...(() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { pageTypeToShow, ...rest } = popupListTable;
              return rest;
            })(),
            page: 1,
          }),
        ),
      key: 'target',
      label: t('target_name', { name: displayPageList(t).find((item) => item.value === popupListTable.pageTypeToShow)?.label || t('all_text') }),
      hidden: !popupListTable.pageTypeToShow,
    },
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...(() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { applyCustomer, ...rest } = popupListTable;
              return rest;
            })(),
            page: 1,
          }),
        ),
      key: 'trigger',
      label: t('trigger_name', { name: triggerCondition(t).find((item) => item.value === popupListTable.applyCustomer)?.label || t('all_text') }),
      hidden: !popupListTable.applyCustomer,
    },
    {
      onRemove: () =>
        dispatch(
          handlePopupListTable({
            ...popupListTable,
            limit: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: t('record_per_page_number', { number: popupListTable.limit })
    },
  ];

  return {
    filters,
    appliedFilters: appliedFilters.filter((item) => !item.hidden),
  };
};
