import BoldText from '@/components/BoldText';
import { config } from '@/config';
import { CheckoutVerifyType } from '@/constants/enum';
import userPlans from '@/hooks/userPlans';
import {
  CheckoutApplyType,
  ColorsCheckoutUITypes,
  handleChangeSetupVerify,
  handleSetupErrorVerify,
  setupErrorVerifySelector,
  setupVerifySelector,
  verifyTypeSelector,
} from '@/redux/slice/checkoutVerification.slice';
import { BlockStack, Box, Button, Card, ChoiceList, Link, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import ProductOrCollectionList from './ProductOrCollectionList';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { colorsCheckoutUI } from '@/constants/colors';

const PreviewColor = ({ bgColor }: { bgColor: string }) => (
  <div
    style={{
      width: '30px',
      height: '30px',
      marginRight: '6px',
      borderRadius: '50%',
      backgroundColor: bgColor,
    }}
  />
);

export const optionsColor = [
  {
    label: 'Accent',
    value: ColorsCheckoutUITypes.ACCENT,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.accent} />,
  },
  {
    label: 'Critical',
    value: ColorsCheckoutUITypes.CRITICAL,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.critical} />,
  },
  {
    label: 'Decorative',
    value: ColorsCheckoutUITypes.DECORATIVE,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.decorative} />,
  },
  {
    label: 'Info',
    value: ColorsCheckoutUITypes.INFO,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.info} />,
  },
  {
    label: 'Subdued',
    value: ColorsCheckoutUITypes.SUBDUED,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.subdued} />,
  },
  {
    label: 'Success',
    value: ColorsCheckoutUITypes.SUCCESS,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.success} />,
  },
  {
    label: 'Warning',
    value: ColorsCheckoutUITypes.WARNING,
    prefix: <PreviewColor bgColor={colorsCheckoutUI.warning} />,
  },
];

interface Props {
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConditionVerify: React.FC<Props> = ({ setActiveModal }) => {
  const dispatch = useDispatch();
  const { shopifyPlanPlus } = userPlans();
  const { t } = useTranslation(["checkout", "common"]);

  const verifyType = useSelector(verifyTypeSelector);
  const setupVerify = useSelector(setupVerifySelector);
  const setupError = useSelector(setupErrorVerifySelector);
  const isShowContextualBar = useSelector(isShowSelector);

  const handleChangeSetup = (key: keyof typeof setupVerify) => (value: string | boolean) => {
    dispatch(
      handleChangeSetupVerify({
        [key]: value,
        ...(key === 'applyTypeBanner' && { showInPageBanner: [] }),
        ...(key === 'applyTypeInput' && { showInPageInput: [] }),
      }),
    );
  };

  const handlePreviewCheckoutEditor = useCallback(() => {
    if (isShowContextualBar && config.embedded === '1') {
      setActiveModal((props) => !props);
    } else {
      window.open(
        `https://admin.shopify.com/store/${config.shop.replace('.myshopify.com', '')}/settings/checkout/editor`,
        '_bank',
      );
    }
  }, [isShowContextualBar, setActiveModal]);

  return (
    <BlockStack gap="200">
      <Card>
        <ChoiceList
          title={<BoldText>{verifyType === CheckoutVerifyType.InfoBanner ? t('banner_status') : t('common:status')} </BoldText>}
          choices={[
            {
              label: t('common:enable'),
              value: 'true',
            },
            {
              label: t('common:disable'),
              value: 'false',
            },
          ]}
          selected={[
            verifyType === CheckoutVerifyType.InfoBanner
              ? setupVerify.bannerActive.toString()
              : setupVerify.validateInputActive.toString(),
          ]}
          onChange={(value) =>
            handleChangeSetup(verifyType === CheckoutVerifyType.InfoBanner ? 'bannerActive' : 'validateInputActive')(
              value[0] === 'true' ? true : false,
            )
          }
        />
      </Card>

      <Card>
        <ChoiceList
          title={<BoldText>{t('target')}</BoldText>}
          choices={[
            { label: t('always'), value: CheckoutApplyType.All, helpText: t('always_show_banner') },
            {
              label: t('common:specific_collection'),
              value: CheckoutApplyType.SpecificCollection,
              helpText: t('show_banner_selected_collection'),
              renderChildren: (isSelected: boolean) => isSelected && <ProductOrCollectionList />,
            },
            {
              label: t('common:specific_product'),
              value: CheckoutApplyType.SpecificProduct,
              helpText: t('show_banner_selected'),
              renderChildren: (isSelected: boolean) => isSelected && <ProductOrCollectionList />,
            },
          ]}
          selected={[verifyType === CheckoutVerifyType.InfoBanner ? setupVerify.applyTypeBanner : setupVerify.applyTypeInput]}
          onChange={(value) => {
            if (setupError.showInPage) dispatch(handleSetupErrorVerify({ showInPage: '' }));
            handleChangeSetup(verifyType === CheckoutVerifyType.InfoBanner ? 'applyTypeBanner' : 'applyTypeInput')(value[0]);
          }}
        />
      </Card>

      {shopifyPlanPlus ? (
        <Card>
          <BlockStack gap={'025'}>
            <Text variant="headingMd" as="h2">
              {t('configuration')}
            </Text>
            <Text variant="bodyMd" as="span">
              {t('customize_ui_content')}{' '}
              <Link
                target="_blank"
                url="https://blockifyapp.com/knowledge-base/uncategorized/set-up-age-verification-at-checkout-for-shopify-plus-only/"
              >
                {t('learn_more')}
              </Link>
            </Text>
          </BlockStack>
          <Box paddingBlockStart={'300'}>
            <Button size="slim" onClick={handlePreviewCheckoutEditor}>
              {t('go_to_checkout_editor')}
            </Button>
          </Box>
        </Card>
      ) : null}
    </BlockStack>
  );
};

export default ConditionVerify;
