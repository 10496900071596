import styled from 'styled-components';

export const TermAndConditionStyled = styled.div`
  .card-item {
    border: 1px solid #ebebeb;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 6px;
    .card-item-top {
      padding: 16px;
      height: auto;
      min-height: 157px;
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      pointer-events: none;
      .card-item-top-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        img {
          margin-right: 0.5rem;
          height: 60px;
        }
        .card-item-top-content {
          width: 100%;
        }
      }
    }
    .card-item-top-checkout {
      padding: 16px;
      height: auto;
      min-height: 150px;
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      width: 100%;
      .width-40 {
        width: 40%;
      }
      .card-item-top-checkout-item {
        height: 60px;
        margin-bottom: 1rem;
      }
      img {
        margin: 0 auto;
        height: 50px;
      }
      .card-item-top-checkout-content {
        pointer-events: none;
      }
      .card-item-top-checkout-right {
        display: flex;
        flex-direction: column;
      }
    }

    .card-item-bottom {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: start;
      min-height: 140px;

      .wrap-button {
        margin-top: auto;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .Polaris-Badge .Polaris-Text--root {
      /* font-weight: 600; */
    }
  }
`;
