import BoldText from '@/components/BoldText';
import ColorPickerCustom from '@/components/ColorPickerCustom';
import { BackgroundColor } from '@/constants/enum';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Card, Divider, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const TextSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["popup", "common"]);
  const previewDetail = useSelector(previewSelector);

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    if (key === 'headingSize' || key === 'subHeadingSize') {
      // Loại bỏ dấu '-' nếu có trong chuỗi đầu vào
      value = value.replace(/-/g, '');

      const isValidNumber = /^[0-9]\d*$/.test(value); // Chỉ cho phép số nguyên dương

      if (isValidNumber) {
        let numericValue = parseInt(value, 10);

        value = numericValue.toString();
      } else {
        value = '';
      }
    }

    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  return (
    <BlockStack gap="200">
      <Card>
        <BoldText>{t('pop_up_heading')}</BoldText>
        <div className="mt-8 mb-8">
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label={t('text')}
            value={previewDetail?.headingText ?? t('heading_preview')}
            onChange={handleChangeTemplate('headingText')}
            maxLength={255}
            showCharacterCount
          />

          <TextField
            autoComplete=""
            label={t('size')}
            value={previewDetail.headingSize}
            onChange={handleChangeTemplate('headingSize')}
            suffix="px"
            // onBlur={() => {
            //   if (!Number.isNaN(previewDetail.headingSize) || Number(previewDetail.headingSize) === 0) {
            //     handleChangeTemplate('headingSize')('20');
            //   }
            // }}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('headingColor')}
            label={t('color')}
            color={previewDetail.headingColor}
            keyColor="headingColor"
            defaultColor={BackgroundColor.White}
          />
        </BlockStack>
      </Card>

      <Card>
        <BoldText>{t('pop_up_sub_heading')}</BoldText>
        <div className="mt-8 mb-8">
          <Divider />
        </div>
        <BlockStack gap="500">
          <TextField
            autoComplete=""
            label={t('text')}
            value={previewDetail?.subHeadingText ?? t('sub_heading_preview')}
            onChange={handleChangeTemplate('subHeadingText')}
            maxLength={255}
            showCharacterCount
          />

          <TextField
            autoComplete=""
            label={t('size')}
            value={previewDetail.subHeadingSize}
            onChange={handleChangeTemplate('subHeadingSize')}
            suffix="px"
            // onBlur={() => {
            //   if (Number(previewDetail.subHeadingSize) === 0) {
            //     handleChangeTemplate('subHeadingSize')('16');
            //   }
            // }}
          />

          <ColorPickerCustom
            onChange={handleChangeTemplate('subHeadingColor')}
            label={t('color')}
            color={previewDetail.subHeadingColor}
            keyColor="subHeadingColor"
            defaultColor={BackgroundColor.White}
          />
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default TextSetting;
