import styled from 'styled-components';
type Props = {};

export const TermAndConditionSetupStyled = styled.div<Props>`
  .preview-checkout-stick {
    position: sticky;
    top: 36px;
    align-self: start;
  }
  .require-indicator {
    color: rgba(142, 31, 11, 1);
    font-size: 13px;
    font-weight: 500;
  }
`;
