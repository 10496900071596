import { ITranslation } from '@/types/components/translation';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IResponseApi } from '@/types/apis/response';
import { apiCaller } from '../query';
const translation: ITranslation = {
  headingText: '',
  subHeadingText: '',
  submitButtonLabel: '',
  cancelButtonLabel: '',
  monthsLabel: {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: '',
  },
  errorMessage: '',
};

export const initialState = {
  translation: translation,
  translationBackup: translation,
  selectedLanguage: '',
  currentPage: 1,
  locales: [] as IResponseApi.ILanguage[],
  ruleInfo: [] as IResponseApi.RuleInfo[],
  localeMonthLabel: [] as string[]
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    handleChangeTranslation: (state, action: PayloadAction<Partial<ITranslation>>) => {
      state.translation = {
        ...state.translation,
        ...action.payload,
      };
    },
    handleCustomizeTranslation: (state, action: PayloadAction<ITranslation>) => {
      state.translation = action.payload;
      state.translationBackup = action.payload;
    },
    handleSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    clearAllState: (state) => {
      state.translation = translation;
      state.translationBackup = translation;
    },
    handleChangeCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    handleSetShopLocales: (state, action: PayloadAction<IResponseApi.ILanguage[]>) => {
      state.locales = action.payload
    },
    handleSetRuleInfo: (state, action: PayloadAction<IResponseApi.RuleInfo[]>) => {
      state.ruleInfo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiCaller.endpoints.getLanguage.matchFulfilled,
      (state, action) => {
        state.locales = action.payload.data; 
      }
    ).addMatcher(
      apiCaller.endpoints.getRuleName.matchFulfilled,
      (state, action) => {
        state.ruleInfo = action.payload.rules; 
      }
    ).addMatcher(
      apiCaller.endpoints.getTranslation.matchFulfilled,
      (state, action) => {
        state.localeMonthLabel = action.payload.showMonthLabel;
      }
    )
  },
});

export const translationSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.translation,
);

export const translationBackupSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.translationBackup,
);

export const selectedLanguageSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.selectedLanguage,
);

export const selectCurrentPageSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.currentPage,
);

export const selectShopLocaleSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.locales,
);

export const selectRuleInfoSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.ruleInfo,
);

export const selectLocaleMonthLabelSelector = createSelector(
  (state: RootState) => state.translation,
  (state) => state.localeMonthLabel,
);

export const {
  handleChangeTranslation,
  handleCustomizeTranslation,
  handleSelectedLanguage,
  handleChangeCurrentPage,
  handleSetShopLocales,
  handleSetRuleInfo
} = translationSlice.actions;
export default translationSlice;
