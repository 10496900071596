import { TFunction } from "i18next";
import { DateOrder, InputMethod, PageType, StatusType, TermsCondition, TriggerType } from "./enum";

export const perPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

export const optionAgeList = [...Array(100).keys()].map((i) => ({ value: String(i + 1), label: String(i + 1) }));

export const choiceMethodList = (t?: TFunction) => [
  { label: t ? t('no_input') : 'No input', value: InputMethod.NONE },
  { label: t ? t('birthdate_entry') : 'No input', value: InputMethod.BIRTHDATE_ENTRY },
];

export const triggerCondition = (t?: TFunction) => [
  { label: t ? t('always_show', { ns: 'popup' }) : "Always show", value: TriggerType.AlwaysShow },
  { label: t ? t('logged_customers', { ns: 'popup' }) : 'Logged customers', value: TriggerType.LoggedCustomers },
];

export const triggerConditionTermAndCondition = (t?: TFunction) => [
  { label: t ? t('always_show') : "Always show", value: TermsCondition.ALWAYS },
  { label: t ? t('logged_customers') : 'Logged customers', value: TermsCondition.LOGGED_IN },
  { label: t ? t('not_logged_customers') : 'Not logged customers', value: TermsCondition.NOT_LOGGED_IN },
];

export const status = (t?: TFunction) => [
  { label: t ? t('common:enabled') : 'Enabled', value: StatusType.Enable },
  { label: t ? t('common:disabled') : 'Disabled', value: StatusType.Disable },
]

export const optionDateOrderList = [
  { label: 'YY,MM,DD', value: DateOrder.YY_MM_DD },
  { label: 'MM,DD,YY', value: DateOrder.MM_DD_YY },
  { label: 'DD,MM,YY', value: DateOrder.DD_MM_YY },
  { label: 'YY,DD,MM', value: DateOrder.YY_DD_MM },
  { label: 'MM,YY,DD', value: DateOrder.MM_YY_DD },
  { label: 'DD,YY,MM', value: DateOrder.DD_YY_MM },
];

export const displayPageList = (t?: TFunction) => [
  { label: t ? t('all_pages', { ns: 'popup' }) : 'All pages', value: PageType.All },
  { label: t ? t('home_page', { ns: 'popup' }) : 'Home page', value: PageType.Home },
  { label: t ? t('specific_collections', { ns: 'popup' }) : 'Specific collections', value: PageType.SpecificCollections },
  { label: t ? t('specific_products', { ns: 'popup' }) : 'Specific products', value: PageType.SpecificProducts },
  { label: t ? t('custom_page', { ns: 'popup' }) : 'Custom page', value: PageType.Custom }
];

export const choiceDisplayOptionSpecific = [
  {
    label: 'Home',
    value: PageType.Home,
  },
  {
    label: 'Collection',
    value: PageType.SpecificCollections,
  },
  {
    label: 'Product',
    value: PageType.SpecificProducts,
  },
  {
    label: 'Custom',
    value: PageType.Custom,
  },
];
