import styled from 'styled-components';

interface Props { }

export const TranslationStyled = styled.div<Props>`
  #custom-label-month {
    margin-bottom: -12px;
  }

  .editor-custom-css {
    section {
      border-radius: 8px;
      overflow: auto;
    }
  }

  .selected-language {
    min-width: 200px;
  }

  .translate-btn-collapse {
    width: 100%;
    margin-top: 8px;
    .Polaris-Button--textAlignCenter {
      width: 100%;
    }
  }
`;