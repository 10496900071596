import styled from 'styled-components';
type Props = {};

export const CheckoutVerificationSetupStyled = styled.div<Props>`
  .preview-checkout-stick {
    position: sticky;
    top: 36px;
    align-self: start;
  }
`;
