import styled from 'styled-components';
interface Props { }

export const PopupCustomizationStyled = styled.div<Props>`
  .card-table {
    margin-top: 1rem;
    position: relative;
    .Polaris-IndexFilters-Container {
      padding-right: 2.75rem;
      padding-left: 0.25rem;
    }
    .btn-delete {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      z-index: 10;
      display: flex;
      gap: 0.5rem;
    }
  }

`;
