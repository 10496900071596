import BoldText from '@/components/BoldText';
import { config } from '@/config';
import { TermsCondition } from '@/constants/enum';
import { triggerConditionTermAndCondition } from '@/constants/options';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { handleChangeSetupTermAndConditionCheckoutUI, setupTermAndConditionCheckoutUISelector } from '@/redux/slice/termAndCondition.slice';
import { BlockStack, Box, Button, Card, ChoiceList, Text } from '@shopify/polaris';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermAndConditionCheckoutUI: React.FC<Props> = ({ setActiveModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['term-condition', 'common']);
  const setupTermAndConditionCheckoutUI = useSelector(setupTermAndConditionCheckoutUISelector);
  const isShowContextualBar = useSelector(isShowSelector);
  const handleChangeSetup = useCallback(
    (key: keyof typeof setupTermAndConditionCheckoutUI) => (value: string | string[] | boolean) => {
      dispatch(
        handleChangeSetupTermAndConditionCheckoutUI({
          [key]: value,
        }),
      );
    },
    [dispatch],
  );

  const handlePreviewCheckoutEditor = useCallback(() => {
    if (isShowContextualBar && config.embedded === '1') {
      setActiveModal((props) => !props);
    } else {
      window.open(
        `https://admin.shopify.com/store/${config.shop.replace('.myshopify.com', '')}/settings/checkout/editor`,
        '_blank',
      );
    }
  }, [isShowContextualBar, setActiveModal]);

  return (
    <BlockStack gap="200">
      <Card>
        <ChoiceList
          title={<BoldText>{t('common:status')}</BoldText>}
          choices={[
            {
              label: t('common:enable'),
              value: 'true',
            },
            {
              label: t('common:disable'),
              value: 'false',
            },
          ]}
          selected={[setupTermAndConditionCheckoutUI.status.toString()]}
          onChange={(value) => handleChangeSetup('status')(value[0] === 'true')}
        />
      </Card>

      <Card>
        <ChoiceList
          title={<BoldText>{t("trigger_condition")}</BoldText>}
          choices={triggerConditionTermAndCondition(t)}
          selected={[setupTermAndConditionCheckoutUI.condition]}
          onChange={(value) => handleChangeSetup('condition')(value[0] as TermsCondition)}
        />
      </Card>

      <Card>
        <BlockStack gap={'025'}>
          <Text variant="headingMd" as="h2">
            {t('configuration_key')}
          </Text>
          <Text variant="bodyMd" as="span">
            {t('customize_terms_conditions_ui_content')}
          </Text>
        </BlockStack>
        <Box paddingBlockStart={'300'}>
          <Button size="slim" onClick={handlePreviewCheckoutEditor}>
            {t('go_to_checkout')}
          </Button>
        </Box>
      </Card>
    </BlockStack>
  );
};

export default TermAndConditionCheckoutUI;
