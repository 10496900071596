import { Icon, Text, TextField } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { TFunction } from 'i18next';
import { ReactElement, useCallback } from 'react';

interface TranslationProps {
  label: string | ReactElement;
  defaultValue?: string;
  value: string;
  loading: boolean;
  loadingPreview: boolean;
  onChange?: (newValue: string) => void;
  t?: TFunction
}

function TextFieldTranslation({ ...props }: TranslationProps) {
  const handleTranslate = useCallback(
    (newValue: string) => {
      if (props.onChange) {
        props.onChange(newValue);
      }
    },
    [props],
  );
  return (
    <div className='space-between align-center'>
      <div className='width-100'>
        <div className='mb-4'>
          <Text variant="bodyMd" as="p">{props.t ? props.t(props.label as string) : props.label}</Text>
        </div>
        <TextField label='' autoComplete="off" value={props.t ? props.t(props.defaultValue as string) : props.defaultValue} disabled loading={props.loadingPreview} />
      </div>
      <div className='ml-4 mr-4 mt-24'>
        <Icon source={ChevronRightIcon} />
      </div>
      <div className='width-100'>
        <div className='mb-4'>
          <Text variant="bodyMd" as="p">{props.label}</Text>
        </div>
        <TextField
          label=''
          autoComplete="off"
          value={props.value}
          onChange={handleTranslate}
          loading={props.loading}
        />
      </div>
    </div>
  )
}

export default TextFieldTranslation
