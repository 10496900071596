import { config } from '@/config';
import { Embedded } from '@/constants/enum';
import { useAppBridge } from '@shopify/app-bridge-react';

const useAppNotEmbeded = () => {
  const shopify = null;
  return shopify;
};

const useShopifyAppBrigde = () => {
  const shopify = useAppBridge();

  return shopify
};

export default config.embedded === Embedded.LIVE ? useShopifyAppBrigde : useAppNotEmbeded;