import { images } from '@/asset';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { clearAllState } from '@/redux/slice/preview.slice';
import toastSlice from '@/redux/slice/Toast/toast.slice';
import { Box, Button, LegacyCard, Modal, Text } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PopupList from './components/Table';
import { PopupCustomizationStyled } from './styled';
import { useTranslation } from 'react-i18next';

const PopupCustomization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["popup", "common"]);
  const [state, setState] = useState({
    isOpenModalDelete: false,
  });
  const [deleteAllItem, deleteAllItemStatus] = apiCaller.useDeleteAllRuleMutation();
  const [clearSelectedChildFn, setClearSelectedChildFn] = useState<(() => void) | null>(null);
  const onParentAction = useCallback((action: () => void) => {
    setClearSelectedChildFn(() => action);
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: false,
    });
  }, [state]);

  const handleDeleteAllRule = useCallback(() => {
    deleteAllItem().then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.statusCode) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res, t)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, dispatch, handleCloseModalDelete, deleteAllItem, t]);

  return (
    <Layout title={t('common:store_verification')}>
      <PopupCustomizationStyled>
        <BannerTemplate src={images.banner_set_up_multiple_pop_up}>
          <BoldText>{t('popup')}</BoldText>
          <div className="mt-8">
            <RegularText>
              {t('verify_customers_age_with_popups')}
            </RegularText>
          </div>
          <div className="mt-16">
            <Button
              variant="primary"
              onClick={() => {
                dispatch(clearAllState());
                navigate(PATH.CUSTOM_TEMPLATE);
              }}
            >
              {t('create_pop_up')}
            </Button>
          </div>
        </BannerTemplate>
        <Box paddingBlockStart={"600"}>
          <Text as={"h2"} variant='headingSm'>{t('pop_up_list')}</Text>
        </Box>
        <div className="card-table">
          <LegacyCard>
            <PopupList onParentAction={onParentAction} />
          </LegacyCard>
        </div>
        <Modal
          open={state.isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title={t('delete_all')}
          primaryAction={{
            destructive: true,
            content: t('common:delete'),
            onAction: handleDeleteAllRule,
            loading: deleteAllItemStatus.isLoading,
          }}
          secondaryActions={[
            {
              content: t('common:cancel'),
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>{t('delete_all_wont_revert')}</RegularText>
          </Modal.Section>
        </Modal>
      </PopupCustomizationStyled>
    </Layout>
  );
};

export default PopupCustomization;
