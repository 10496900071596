import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { apiCaller } from '@/redux/query';
import bannerSlice, { bannerNoticeEmbeddedAppSelector, showReferAppSelector } from '@/redux/slice/banner.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, Page, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { link } from '@/constants/link';
import { useDispatch, useSelector } from 'react-redux';
import FAQ from './FAQ';
import Overview from './Overview';
import { BannerStype, DashboardStyled } from './styled';
import ThingsToDoNext from './thingsToDoNext';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import BannerTemplate from '@/components/BannerTemplate';
import { images } from '@/asset';
import { gaFourSelector } from '@/redux/slice/session.slice';
import { Embedded, Ga4Event } from '@/constants/enum';
import { useGa4 } from '@/hooks/useGa4';
import RecommenedApps from './recommenedApps';
import { PATH } from '@/constants/path';
import { useLocation } from 'react-router-dom';
import { config } from '@/config';
import { Trans, useTranslation } from 'react-i18next';

const Dashboard = () => {
  const twitterRef = useRef(null);
  const recommendAppRef = useRef(null);
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const showReferApp = useSelector(showReferAppSelector);
  const bannerNoticeEmbeddedApp = useSelector(bannerNoticeEmbeddedAppSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });
  const gaFour = useSelector(gaFourSelector);
  const { handleGa4 } = useGa4();
  const location = useLocation();
  const { t } = useTranslation(["dashboard", "common"]);

  const [showBannerReferApp, setShowBannerReferApp] = useState(false);

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: t('contact_support'),
        description: (
          <span>
            {/* {t('we_provide_support')} */}
            <Trans t={t} i18nKey={'we_provide_support'}>
              We provide <b>24/7</b> support, feel free to contact us if you get any problems with the app.
            </Trans>
            {/* We provide <b>24/7</b> support, feel free to contact us if you get any problems with the app. */}
          </span>
        ),
        onAction: () => {
          const sendText = 'I need assistance with using Age Verify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreAgeVerification']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: t('chat_with_us'),
        icon: ChatIcon,
      },
      {
        title: t('read_user_guideline'),
        description: t('step_by_step_instruction_articles'),
        onAction: () => {
          window.open('https://blockifyapp.com/knowledge-base/blockify-age-verification/');
        },
        btnContent: t('read_user_guideline'),
        icon: BookIcon,
      },
      {
        title: t('discover_use_cases'),
        description: t('explore_helpful_articles_rule_use_cases_verifying_age'),
        onAction: () => {
          window.open('https://blockifyapp.com/knowledge-base/blockify-age-verification/settings');
        },
        btnContent: t('view_use_cases'),
        icon: GlobeAsiaFilledIcon
      },
    ];
  }, [t]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleGa4(Ga4Event.ImpressTwitter);
        }
      });
    });

    if (twitterRef.current) {
      observer.observe(twitterRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (twitterRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(twitterRef.current);
      }
    };
  }, [gaFour, handleGa4, twitterRef]);

  const handleRedirectToCheckoutRuleApp = () => {
    window.open('https://apps.shopify.com/blockify?utm_campaign=crs&utm_source=age&utm_medium=homeinapp', '_blank');
  };

  const handleCloseBannerReferApp = useCallback(() => {
    setShowBannerReferApp(false);
    dispatch(bannerSlice.actions.handleShowReferApp(false));
  }, [dispatch]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (showReferApp && location.pathname === PATH.DEFAULT.pathname) {
        setShowBannerReferApp(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [showReferApp, location.pathname]);

  return (
    <>
      {config.embedded === Embedded.LIVE ? (
        <BannerStype>
          <Page>
            <Collapsible
              id="banner-check-refer-app"
              open={showBannerReferApp}
              transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
            >
              <div className="banner-refer-app">
                <Banner onDismiss={handleCloseBannerReferApp} tone="info" title="" hideIcon>
                  <div className="banner-wrapper-content" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="d-flex banner-refer-app-content">
                      <img className="banner-refer-app-logo" src={images.blockify} alt="banner-refer-checkout-rule"></img>
                      <RegularText>
                        {t('block_unwanted_access')}.
                      </RegularText>
                    </div>
                    <div className="btn-set-up" style={{ marginRight: '0.5em' }}>
                      <Button target="_blank" onClick={handleRedirectToCheckoutRuleApp}>
                        {t('set_up_now')}
                      </Button>
                    </div>
                  </div>
                </Banner>
              </div>
            </Collapsible>
          </Page>
        </BannerStype>
      ) : null}

      <Layout isBigHeader title={t('hello_name', { name: fetchDataSettings.data?.data?.nameShop ?? "store" }) + ',' }>
        <DashboardStyled>
          <div className="fz-18">
            <Text variant="bodyLg" as="span">
              {t('welcome_age_verification_popup', { name: 'Age Verification Pop-up' })} 🎉
            </Text>
          </div>

          {checkEmbeddedStatus.data && (
            <Collapsible
              id="banner-check-embedded-app"
              open={bannerNoticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
              transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
            >
              <div className="mt-16">
                <Banner
                  onDismiss={() => {
                    dispatch(bannerSlice.actions.handleBannerNoticeEmbeddedApp(false));
                  }}
                  tone="warning"
                  title={t('age_verification_popup_not_activated', { name: 'Age Verification Pop-up' })}
                >
                  <div className="d-flex flex-column">
                    <RegularText>
                      {t('enable_app_popup')}
                    </RegularText>
                    <div className="mt-8">
                      <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                        {t('activate_app')}
                      </Button>
                    </div>
                  </div>
                </Banner>
              </div>
            </Collapsible>
          )}

          <ThingsToDoNext
            embedStatus={checkEmbeddedStatus.data?.status}
            hasCustomize={fetchDataSettings.data?.data.customizedSettings || false}
            hasTestApp={fetchDataSettings.data?.data.completedSetup || false}
            shop={fetchDataSettings.data?.data.shop || ''}
            isLoadingFetchDataSettings={fetchDataSettings.isLoading}
          />

          <div className="mt-16">
            <Overview />
          </div>
          <div className="card-info">
            <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
              {infoAndSupport.map((item, index) => {
                return (
                  <Card key={index}>
                    <div className="card-container">
                      <div>
                        <div className="d-flex">
                          <Icon source={item.icon} />
                          <div className="ml-8">
                            <BoldText>{item.title}</BoldText>
                          </div>
                        </div>
                        <div className="card-text">
                          <RegularText>{item.description}</RegularText>
                        </div>
                      </div>
                      <div>
                        <Button onClick={item.onAction}>{item.btnContent}</Button>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </InlineGrid>
          </div>

          <div className="mt-16">
            <FAQ></FAQ>
          </div>

          <div className="mt-16" ref={twitterRef}>
            <BannerTemplate src={images.twitter}>
              <BoldText>{t('bad_track')}</BoldText>
              <div className="mt-8">
                <RegularText>
                  <b>{t('capi_and_analytics')}.</b> {t('no_more_losss_data')}
                </RegularText>
              </div>
              <div className="mt-16">
                <Button
                  url="https://apps.shopify.com/twitter-multi-pixels?utm_campaign=crs&utm_source=age&utm_medium=homeinapp"
                  target="_blank"
                  variant="primary"
                  onClick={() => {
                    handleGa4(Ga4Event.ClickTwitter);
                  }}
                >
                  {t('set_up_now')}
                </Button>
              </div>
            </BannerTemplate>
          </div>

          <div className="app-recommended" ref={recommendAppRef}>
            <RecommenedApps />
          </div>
        </DashboardStyled>
      </Layout>
    </>
  );
};
export default Dashboard;
