import BannerCheckoutUI from '@/components/BannerCheckoutUI';
import BoldText from '@/components/BoldText';
import { colorsCheckoutUI } from '@/constants/colors';
import { CheckoutVerifyType, DeviceType } from '@/constants/enum';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { BannerStyleType, setupVerifySelector, verifyTypeSelector } from '@/redux/slice/checkoutVerification.slice';
import { BlockStack, Box, Collapsible, Divider, Icon, InlineGrid, InlineStack, Text, Thumbnail } from '@shopify/polaris';
import { CaretDownIcon, CartIcon, ChevronDownIcon, DesktopIcon, MobileIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { bannerStyles } from '../BannerVerify';
import { PreviewCheckoutStyled } from './styled';
import { useTranslation } from 'react-i18next';

const PreviewCheckout = () => {
  const { shopifyPlanPlus } = userPlans();
  const { t } = useTranslation("checkout");

  const verifyType = useSelector(verifyTypeSelector);
  const setupVerify = useSelector(setupVerifySelector);
  const isSkip = useSelector(isSkipApiSelector);

  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery('', { skip: isSkip });

  const [selectedDevice, setSelectedDevice] = useState(DeviceType.DESKTOP);
  const isSelectMobile = selectedDevice === DeviceType.MOBILE;

  const bannerStyleRegular = bannerStyles.find((banner) => banner.value === BannerStyleType.Critical);

  const bannerStyledPlus = bannerStyles.find((banner) => banner.value === setupVerify.bannerStyle);

  return (
    <PreviewCheckoutStyled selectedDevice={selectedDevice} className="preview-checkout">
      <Box paddingBlock="200">
        <InlineStack align="center" blockAlign="center" gap="200">
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.DESKTOP ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.DESKTOP)}
          >
            <Icon source={DesktopIcon} />
          </div>
          <div
            className={`card-btn-device ${selectedDevice === DeviceType.MOBILE ? 'is-selected' : ''}`}
            onClick={() => setSelectedDevice(DeviceType.MOBILE)}
          >
            <Icon source={MobileIcon} />
          </div>
        </InlineStack>
      </Box>
      <Divider />

      <Box paddingBlock="400" paddingInline={isSelectMobile ? '3200' : '400'}>
        <InlineStack align="space-between">
          <Text as="h1" variant="headingLg">
            {fetchDataSettings.data?.data.nameShop}
          </Text>
          <Icon source={CartIcon} tone="emphasis" />
        </InlineStack>
      </Box>

      <Collapsible
        id=""
        open={selectedDevice === DeviceType.MOBILE}
        transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
      >
        <Divider />
        <div className="show-order-summary">
          <Box paddingBlock="400" paddingInline={isSelectMobile ? '3200' : '400'}>
            <InlineStack align="space-between">
              <InlineStack blockAlign="center" gap="100">
                <Text as="p" tone="magic-subdued" variant="headingMd">
                  {t('show_order_summary')}
                </Text>
                <Icon source={ChevronDownIcon} tone="magic" />
              </InlineStack>
              <Text as="p" variant="headingMd">
                $25.98
              </Text>
            </InlineStack>
          </Box>
        </div>
      </Collapsible>

      <Divider />
      <div className="checkout-preview">
        <div className="checkout-preview-left">
          <Collapsible
            open={verifyType === CheckoutVerifyType.InfoBanner && setupVerify.bannerActive}
            id=""
            transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          >
              <BannerCheckoutUI
                banner={shopifyPlanPlus ? bannerStyledPlus! : bannerStyleRegular!}
                heading={shopifyPlanPlus || !setupVerify.heading ? t('must_be_18_years_old') : setupVerify.heading.trim()}
                subHeading={shopifyPlanPlus ? t('ensure_you_read_and_agree') : ' '}
              />
          </Collapsible>

          <BlockStack gap="200">
            <BoldText>{t('contact')}</BoldText>
            <div className="checkout-input">
              <Text as="p" variant="bodyLg" tone="subdued">
                {t('email_or_mobile_phone_number')}
              </Text>
            </div>
          </BlockStack>

          <InlineStack blockAlign="start" gap="200">
            <div className="checkbox-input"></div>
            <p style={{ color: '#000' }}>{t('email_me_news_offers')}</p>
          </InlineStack>

          <Collapsible
            open={setupVerify.validateInputActive && verifyType === CheckoutVerifyType.CheckboxOrDate}
            id=""
            transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          >
            <BlockStack gap="400">
              <BlockStack gap="200">
                <BoldText>{t('confirm_your_age')}</BoldText>
                <div className="checkout-input">
                  <InlineStack blockAlign="start" gap="200" wrap={false}>
                    <div className="checkbox-input" />
                    <p style={{ color: colorsCheckoutUI[setupVerify.textColor] }}>{t('im_over_18_years_old')}</p>
                  </InlineStack>
                </div>
              </BlockStack>

              <BlockStack gap="200">
                <Text as="p" variant="headingMd" truncate breakWord>
                  {t('enter_your_birth_date')}
                </Text>
                <DateOrderVerify />
              </BlockStack>
            </BlockStack>
          </Collapsible>

          <BlockStack gap="200">
            <BoldText>{t('delivery')}</BoldText>
            <div className="checkout-input" style={{ padding: '4px 12px' }}>
              <InlineStack align="space-between" blockAlign="center">
                <div>
                  <Text as="p" variant="bodySm" tone="subdued">
                    {t('country_region')}
                  </Text>
                  <Text as="p">Vietnam</Text>
                </div>
                <Icon source={CaretDownIcon} />
              </InlineStack>
            </div>
          </BlockStack>

          <InlineGrid columns={2} gap="200">
            <div className="checkout-input">
              <Text as="p" tone="subdued">
                {t('first_name_option')}
              </Text>
            </div>
            <div className="checkout-input">
              <Text as="p" tone="subdued">
                {t('last_name')}
              </Text>
            </div>
          </InlineGrid>
        </div>

        <div className="checkout-preview-right">
          <InlineStack align="space-between" blockAlign="center">
            <InlineStack gap="300" blockAlign="center">
              <Thumbnail
                source="https://burst.shopifycdn.com/photos/black-leather-choker-necklace_373x@2x.jpg"
                alt="Black choker necklace"
              />
              <BlockStack>
                <Text as="p">{t('product_demo')}</Text>
                <Text as="p" variant="bodySm" tone="subdued">
                  {t('variant_demo')}
                </Text>
              </BlockStack>
            </InlineStack>

            <Text as="p">$19.99</Text>
          </InlineStack>

          <BlockStack gap="200">
            <InlineStack align="space-between" blockAlign="center">
              <Text as="p">{t('subtotal')}</Text>
              <Text as="p">$19.99</Text>
            </InlineStack>

            <InlineStack align="space-between" blockAlign="center">
              <Text as="p">{t('shipping')}</Text>
              <Text as="p">{t('free')}</Text>
            </InlineStack>
            <InlineStack align="space-between" blockAlign="center">
              <InlineStack>
                <Text as="p">{t('estimated_taxes')}</Text>
                <Icon tone="subdued" source={QuestionCircleIcon} />
              </InlineStack>
              <Text as="p">$5.99</Text>
            </InlineStack>
          </BlockStack>

          <InlineStack align="space-between" blockAlign="center">
            <Text as="p" variant="headingLg">
              {t('total')}
            </Text>
            <InlineStack gap="100">
              <Text as="p" variant="bodySm">
                USD
              </Text>
              <Text as="p" variant="headingMd">
                $25.98
              </Text>
            </InlineStack>
          </InlineStack>
        </div>
      </div>

      <Divider />
      <Box padding="400"></Box>
    </PreviewCheckoutStyled>
  );
};

export default PreviewCheckout;

export const DateOrderVerify = () => {
  const { t } = useTranslation(["common"]);
  const setupVerify = useSelector(setupVerifySelector);
  const dataOrders = setupVerify.dateOrder.split(',');

  const renderOrder = (label: string, value: string) => (
    <div
      key={value}
      className="checkout-input"
      style={{ padding: '4px 12px', background: '#fff', border: '1px solid #ccc', borderRadius: '6px' }}
    >
      <InlineStack wrap={false} align="space-between" blockAlign="center">
        <BlockStack>
          <Text as="span" variant="bodySm" tone="subdued">
            {t(label)}
          </Text>
          <Text as="span">{value}</Text>
        </BlockStack>
        <Icon source={CaretDownIcon} />
      </InlineStack>
    </div>
  );

  return (
    <InlineGrid columns={3} gap="200">
      {dataOrders.map((order) => {
        switch (order) {
          case 'YY':
            return renderOrder('year', 'YY');
          case 'MM':
            return renderOrder('month', 'MM');
          case 'DD':
            return renderOrder('day', 'DD');
          default:
            return null;
        }
      })}
    </InlineGrid>
  );
};
