import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { apiCaller } from '../query';
import { IResponseApi } from '@/types/apis/response';

interface IShopInfo {
    domain: string,
    appStatus: boolean,
    plan: string,
    shopifyPlan: string,
    nameShop: string,
    cacheTime: number,
    showWatermark: boolean,
    email: string
}
// Define the initial state using that type
export const initialState: IShopInfo = {
    domain: '',
    appStatus: true,
    plan: '',
    shopifyPlan: '',
    nameShop: '',
    cacheTime: 10,
    showWatermark: true,
    email: ''
};

export const shopSlice = createSlice({
    name: 'banner',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        handleChangeShopSetting(state, action: PayloadAction<IShopInfo>) {
            Object.assign(state, action.payload);
        },
        handleChangeAppStatus(state, action: PayloadAction<boolean>) {
            state.appStatus = action.payload
        },
        handleChangeCacheTime(state, action: PayloadAction<number>){
            state.cacheTime = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            apiCaller.endpoints.getGeneralDetail.matchFulfilled,
            (state, action: PayloadAction<IResponseApi.GeneralDetail>) => {
                state.appStatus = action.payload.data.appStatus;
                state.domain = action.payload.data.shop;
                state.nameShop = action.payload.data.nameShop;
                state.plan = action.payload.data.plan;
                state.shopifyPlan = action.payload.data.shopifyPlan;
                state.cacheTime = action.payload.data.cacheTime;
                state.showWatermark = action.payload.data.showWatermark;
                state.email = action.payload.data.email;
            }
        );
    }
});

export const appStatusSelector = createSelector(
    (state: RootState) => state.shop,
    (state) => state.appStatus
);

export const showWatermarkSelector = createSelector(
    (state: RootState) => state.shop,
    (state) => state.showWatermark
);

export const appInfoSelector = createSelector(
    (state: RootState) => state.shop,
    (state) => state
);

export const shopifyPlanSelector = createSelector(
    (state: RootState) => state.shop,
    (state) => state.shopifyPlan
);

export const shopSelector = createSelector(
    (state: RootState) => state.shop,
    (state) => state
);

export const { handleChangeShopSetting, handleChangeAppStatus, handleChangeCacheTime } = shopSlice.actions;
export default shopSlice;