import BoldText from '@/components/BoldText';
import { DisplayPages, TermsCondition } from '@/constants/enum';
import { triggerConditionTermAndCondition } from '@/constants/options';
import {
  handleChangeSetupTermAndCondition, setupTermAndConditionSelector
} from '@/redux/slice/termAndCondition.slice';
import { BlockStack, Card, ChoiceList, InlineStack } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props { }

const ConditionVerify: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['term-condition', 'common']);
  const setupTermAndCondition = useSelector(setupTermAndConditionSelector);
  const normalizedDisplayPages = Array.from(
    new Set(setupTermAndCondition?.displayPages?.map(page => page.trim()))
  );
  const [displayPagesError, setDisplayPagesError] = useState<string | undefined>(undefined);

  const handleChangeSetup = useCallback(
    (key: keyof typeof setupTermAndCondition) => (value: string | string[] | boolean) => {
      if (key === 'displayPages') {
        const newValue = value as string[];
        if (newValue.length === 0) {
          setDisplayPagesError(t('field_required') || 'This field is required');
        } else {
          setDisplayPagesError(undefined);
        }
      }

      dispatch(
        handleChangeSetupTermAndCondition({
          [key]: value,
        }),
      );
    },
    [dispatch, t],
  );

  return (
    <BlockStack gap="200">
      <Card>
        <ChoiceList
          title={<BoldText>{t('common:status')}</BoldText>}
          choices={[
            {
              label: t('common:enable'),
              value: 'true',
            },
            {
              label: t('common:disable'),
              value: 'false',
            },
          ]}
          selected={[setupTermAndCondition.status.toString()]}
          onChange={(value) => handleChangeSetup('status')(value[0] === 'true')}
        />
      </Card>

      <Card>
        <BlockStack gap="200">
          <ChoiceList
            title={
              <InlineStack gap="050">
                <BoldText>{t('display_pages')}</BoldText>
                <span className="require-indicator">*</span>
              </InlineStack>
            }
            choices={[
              { label: t('product_page'), value: DisplayPages.PRODUCT },
              { label: t('cart_page'), value: DisplayPages.CART },
            ]}
            selected={normalizedDisplayPages}
            onChange={(value) => handleChangeSetup('displayPages')(value as DisplayPages[])}
            allowMultiple
            error={displayPagesError}
          />
          <ChoiceList
            title={<BoldText>{t('trigger_condition')}</BoldText>}
            choices={triggerConditionTermAndCondition(t)}
            selected={[setupTermAndCondition?.condition || TermsCondition.ALWAYS]}
            onChange={(value) => handleChangeSetup('condition')(value[0] as TermsCondition)}
          />
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default ConditionVerify;