import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const CustomTemplateStyled = styled.div`
  .Polaris-Page {
    padding: 0;
    .Polaris-Page__Content {
      padding: 0;
    }
  }
  .btn-cancel {
    text-align: end;
    padding-bottom: 1rem;
  }
  .preview-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
    .preview-heading-btn {
      display: flex;
    }
  }
  .card-left {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #eee;
  }
  .preview-popup-sticky {
    position: sticky;
    top: 36px;
    align-self: start;
  }
  .card-right {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card-btn-device {
      margin: 0 auto;
      width: 60px;
      display: flex;
      padding: 0.5rem 0;
      .card-btn-device-item {
        padding: 8px;
        cursor: pointer;
        border-radius: 10px;
        &.active {
          background: #e3e3e3;
        }
      }
    }
    .card-btn-device-mobile {
      margin: 0 auto;
      display: flex;
      .card-btn-device-item {
        padding: 8px;
        cursor: pointer;
        border-radius: 10px;
        &.active {
          background: #e3e3e3;
        }
      }
    }
    .card-right-preview {
      padding: 0 140px;
    }
    @media screen and (max-width: ${BREAKPOINT.XS}px) {
      .card-right-preview {
        width: 100%;
        padding: 0;
      }
    }
  }
`;

export const CustomTabStyled = styled.div`
  .Polaris-Tabs__Outer .Polaris-Box {
    padding-left: 0 !important;
  }
`;
