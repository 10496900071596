import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the initial state using that type
const initialState = {
  noticeEmbeddedApp: true,
  showThingsToDoNext: true,
  showRecommendActions: true,
  showReferApp: true
};

export const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // handleVisibleBanner: (
    //   state,
    //   action: PayloadAction<{
    //     key: keyof typeof initialState;
    //     value: boolean;
    //   }>,
    // ) => {
    //   state[action.payload.key] = action.payload.value;
    // },
    handleBannerNoticeEmbeddedApp: (state, action: PayloadAction<boolean>) => {
      state.noticeEmbeddedApp = action.payload;
    },
    handleShowThingsToDoNext: (state, action: PayloadAction<boolean>) => {
      state.showThingsToDoNext = action.payload;
    },
    handleShowRecommendActions: (state, action: PayloadAction<boolean>) => {
      state.showRecommendActions = action.payload;
    },
    handleShowReferApp: (state, action: PayloadAction<boolean>) => {
      state.showReferApp = action.payload;
    }
  },
});
export const bannerNoticeEmbeddedAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.noticeEmbeddedApp,
);
export const showThingsToDoNextSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showThingsToDoNext,
);
export const showRecommendActionSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showRecommendActions
);
export const showReferAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showReferApp
);

export default bannerSlice;
