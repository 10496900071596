import BoldText from '@/components/BoldText';
import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import { config } from '@/config';
import { Embedded } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import i18n from '@/i18n';
import { apiCaller } from '@/redux/query';
import { initialState } from '@/redux/slice/shop.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Page,
  Select,
  Layout as ShopifyLayout,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Settings = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [updateShopSetting, { isLoading }] = apiCaller.useUpdateAppSettingsMutation();
  const { data: shopGeneral } = apiCaller.useGetGeneralDetailQuery(undefined);
  const { smUp } = useBreakpoints();
  const { t } = useTranslation(['setting', 'common']);

  const [appSetting, setAppSetting] = useState({
    cacheTime: 10,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);

  const appGeneralRef = useRef(appSetting);

  const supportLanguages = [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Deutsch',
      value: 'de',
    },
    {
      label: 'Français',
      value: 'fr',
    },
    {
      label: 'Italiano',
      value: 'it',
    },
    {
      label: 'Español',
      value: 'es',
    },
    {
      label: 'हिन्दी (Hindi)',
      value: 'hi',
    },
    // {
    //   label: 'Tiếng việt',
    //   value: 'vi',
    // },
  ];

  const handleChangeAppStatusValue = useCallback(async () => {
    setLoadingBtn(true);
    const res = await updateShopSetting({
      appStatus: Boolean(!shopGeneral?.data.appStatus),
    });
    const resToast = handleToastMutation(res, t);
    if ('data' in res) {
      appGeneralRef.current = { ...appSetting };
      dispatch(handleToast({ ...resToast, content: t('text_updated') }));
    } else {
      const isError = checkShowErrorInline(res);
      if (isError.statusCode) {
        dispatch(handleToast(handleToastMutation(isError.message, t)));
      }
    }
    setLoadingBtn(false);
  }, [appSetting, dispatch, t, updateShopSetting, shopGeneral]);

  const handleChangeCacheTimeValue = useCallback((value: string) => {
    setAppSetting((pre) => ({ ...pre, cacheTime: Number(value) <= 0 ? 0 : parseInt(value) }));
  }, []);

  const areSettingEqual = useMemo(() => {
    return isEqual(appSetting, appGeneralRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSetting, appGeneralRef.current]);

  const handleDiscard = () => {
    setAppSetting({ ...appGeneralRef.current });
  };

  const handleChangeLanguage = useCallback((value: string) => {
    i18n.changeLanguage(value).then(() => {
      dispatch(handleToast({
        content: t('text_updated'),
        error: false,
        isOpen: true,
      }));
    });
  }, [dispatch, t]);

  const handleSave = async () => {
    const res = await updateShopSetting({
      cacheTime: appSetting.cacheTime,
    });
    const resToast = handleToastMutation(res, t);

    if ('data' in res) {
      appGeneralRef.current = { ...appSetting };
      dispatch(handleToast({ ...resToast, content: t('text_updated') }));
      return;
    } else {
      const isError = checkShowErrorInline(res);
      if (isError.statusCode) {
        dispatch(handleToast(handleToastMutation(isError.message, t)));
      }
    }
  };

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
        primaryAction: {
          content: t('common:save'),
          onAction: handleSave,
          disabled: areSettingEqual,
          loading: isLoading,
        },
        secondaryActions: (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button disabled={areSettingEqual} onClick={handleDiscard}>
              {t('common:discard')}
            </Button>
          </div>
        ),
      }
      : null;

  useEffect(() => {
    if (shopGeneral && shopGeneral.data) {
      setAppSetting({
        cacheTime: shopGeneral.data.cacheTime,
      });
      appGeneralRef.current = {
        cacheTime: shopGeneral.data.cacheTime,
      };
    }
  }, [shopGeneral]);

  return (
    <Layout title={t('settings')} {...layoutSettings}>
      <Page>
        <ShopifyLayout>
          {config.embedded === Embedded.LIVE ? (
            <>
              <ContextualBar
                delay={state?.prePath}
                value={appSetting}
                loadingSave={isLoading}
                disabledDiscard={areSettingEqual}
                disabledSave={areSettingEqual}
                onDiscard={handleDiscard}
                onSave={handleSave}
              />
            </>
          ) : null}
          <ShopifyLayout.AnnotatedSection
            id="general"
            title={t('general')}
            description={t('general_subheading')}
          >
            <Card>
              <InlineGrid columns={'1fr auto'}>
                <Box paddingBlockStart={'100'}>
                  <InlineStack gap={'150'}>
                    <Text as="h1" variant="headingSm">
                      {t('active_app_name', { appName: 'Age Verification' })}{' '}
                      {shopGeneral?.data.appStatus ? (
                        <Badge size="small" tone="success">
                          {t('common:on')}
                        </Badge>
                      ) : (
                        <Badge size="small" tone="warning">
                          {t('common:off')}
                        </Badge>
                      )}
                    </Text>
                  </InlineStack>
                </Box>
                <Button onClick={handleChangeAppStatusValue} loading={loadingBtn}>
                  {shopGeneral?.data.appStatus ? t('turn_off') : t('turn_on')}
                </Button>
              </InlineGrid>
            </Card>
          </ShopifyLayout.AnnotatedSection>

          {smUp ? <Divider /> : null}

          <ShopifyLayout.AnnotatedSection
            id="storeDetails"
            title={t('advanced')}
            description={t('advanced_subheading')}
          >
            <BlockStack gap={'300'}>
              <Card>
                <Select
                  label={<BoldText>{t('admin_language')}</BoldText>}
                  options={supportLanguages}
                  value={i18n.language}
                  onChange={handleChangeLanguage}
                />
                <Box paddingBlockStart={'100'}>
                  <Text variant="bodyMd" as="span">
                    {t('translated_immediately')}
                  </Text>
                </Box>
              </Card>
              <Card>
                {/* <FormLayout> */}
                <TextField
                  autoComplete=""
                  label={<BoldText>{t('remember_visitor_for')}</BoldText>}
                  value={appSetting.cacheTime?.toString()}
                  type="number"
                  onChange={handleChangeCacheTimeValue}
                  onBlur={() => {
                    if (appSetting.cacheTime === null || appSetting.cacheTime === undefined)
                      handleChangeCacheTimeValue(Number(initialState.cacheTime).toFixed());
                  }}
                  suffix={t('days') === 'days' ? 'day(s)' : t('days')}
                />
              </Card>
            </BlockStack>
          </ShopifyLayout.AnnotatedSection>
        </ShopifyLayout>
      </Page>
    </Layout>
  );
};

export default Settings;
