import { images } from '@/asset';
import Layout from '@/components/layout';
import SkeletonCustom from '@/components/SkeletonCustom';
import { DisplayPages, TermsType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { clearAllStateTermAndCondition, handleChangeTermAndConditionType, handlePushDataTermAndCondition, handlePushDataTermAndConditionCheckoutUI, ISetupTermAndCondition, ISetupTermAndConditionCheckoutUI, setupTermAndConditionCheckoutUISelector, setupTermAndConditionSelector } from '@/redux/slice/termAndCondition.slice';
import { Badge, Button, Checkbox, InlineGrid, InlineStack, Text } from '@shopify/polaris';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TermAndConditionStyled } from './styled';

function TermAndCondition() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopifyPlanPlus } = UserPlans();
  const { t } = useTranslation(["term-condition", "common"]);
  const termAndCondition = useSelector(setupTermAndConditionSelector);
  const termAndConditionCheckoutUI = useSelector(setupTermAndConditionCheckoutUISelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { data: checkoutData, isLoading: isLoadingCheckout } = apiCaller.useGetTermAndConditionQuery({ type: TermsType.CHECKOUT }, { skip: isSkip });
  const { data: storefrontData, isLoading: isLoadingStorefront } = apiCaller.useGetTermAndConditionQuery({ type: TermsType.STOREFRONT }, { skip: isSkip });

  useEffect(() => {
    if (!termAndCondition.id && storefrontData?.data) {
      const mappedStorefrontData: ISetupTermAndCondition = {
        id: storefrontData.data.id,
        shop: storefrontData.data.shop,
        status: storefrontData.data.status,
        type: storefrontData.data.type,
        displayPages: storefrontData.data.displayPages.split(',') as DisplayPages[],
        condition: storefrontData.data.condition,
        checkBoxSize: storefrontData.data.checkboxSize,
        checkBoxColor: storefrontData.data.checkboxColor,
        messageContent: storefrontData.data.messageContent,
        keyword: storefrontData.data.keyword,
        link: storefrontData.data.link,
        messageError: storefrontData.data.messageError,
        messageSize: storefrontData.data.messageSize,
        messageColor: storefrontData.data.messageColor,
      };
      dispatch(handlePushDataTermAndCondition(mappedStorefrontData));
    }

    if (!termAndConditionCheckoutUI.id && checkoutData?.data) {
      const mappedCheckoutData: ISetupTermAndConditionCheckoutUI = {
        id: checkoutData.data.id,
        shop: checkoutData.data.shop,
        status: checkoutData.data.status,
        type: checkoutData.data.type,
        condition: checkoutData.data.condition,
      };
      dispatch(handlePushDataTermAndConditionCheckoutUI(mappedCheckoutData));
    }

    if (!storefrontData?.data && !checkoutData?.data) {
      dispatch(clearAllStateTermAndCondition());
    }
  }, [checkoutData, storefrontData, dispatch, termAndCondition.id, termAndConditionCheckoutUI.id]);

  const isLoading = isLoadingCheckout || isLoadingStorefront;

  return (
    <Layout
      title={t('terms_and_conditions')}
      subtitle={t('terms_and_conditions_setup')}
    >
      <TermAndConditionStyled>
        <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
          <div className="card-item">
            <div className="card-item-top">
              <div className='card-item-top-icon'>
                <img width={60} src={images.term_and_condition_product_and_cart} alt="term_and_condition_product_and_cart" />
                <div className="card-item-top-content">
                  <div className="mb-16">
                    <SkeletonCustom size="small" maxWidth={`${65}%`} height={'20px'} opacity />
                  </div>
                  <SkeletonCustom size="small" maxWidth={`${100}%`} height={'20px'} opacity />
                </div>
              </div>
              <Checkbox label={t('accept_terms_conditions')} />
            </div>
            <div className="card-item-bottom">
              <Text as="h4" variant="headingMd">
                {t('product_cart_page')}
              </Text>
              <Text as="h5" tone="subdued">
                {t('manage_terms_conditions_customer_notices')}
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  onClick={() => {
                    dispatch(handleChangeTermAndConditionType(TermsType.STOREFRONT));
                    navigate(PATH.TERM_AND_CONDITION_SETUP);
                  }}
                >
                  {t('customize_key')}
                </Button>
                {!isLoading && (
                  <Badge tone={termAndCondition.id ? (termAndCondition.status ? 'success' : 'new') : 'new'}>
                    {termAndCondition.id ? (termAndCondition.status ? t('common:enabled') : t('common:disabled')) : t('common:disabled')}
                  </Badge>
                )}
              </div>
            </div>
          </div>
          <div className="card-item">
            <div className="card-item-top-checkout">
              <div className='d-flex card-item-top-checkout-item'>
                <div className='width-40'>
                  <div className="mb-16">
                    <SkeletonCustom size="small" maxWidth={`${100}%`} height={'20px'} opacity />
                  </div>
                  <SkeletonCustom size="small" maxWidth={`${65}%`} height={'20px'} opacity />
                </div>
                <img src={images.term_and_condition_checkout} alt="term_and_condition_checkout" width={50}></img>
                <div className='width-40 card-item-top-checkout-right'>
                  <div className="mb-16">
                    <SkeletonCustom size="small" maxWidth={`${100}%`} height={'20px'} opacity />
                  </div>
                  <SkeletonCustom size="small" maxWidth={`${65}%`} height={'20px'} opacity />
                </div>
              </div>
              <div className='card-item-top-checkout-content'>
                <Checkbox label={t('terms_conditions_agreement')} />
                <Text as="h5" tone="critical">{t('agreement_to_terms_conditions_required')}</Text>
              </div>
            </div>
            <div className="card-item-bottom">
              <InlineStack gap="200" blockAlign="center">
                <Text as="h4" variant="headingMd">
                  {t('checkout_page')}
                </Text>
                <Badge tone="info">Shopify Plus</Badge>
              </InlineStack>
              <Text as="h5" tone="subdued">
                {t('verify_age_checkout')}
              </Text>
              <div className="wrap-button">
                <Button
                  loading={isLoading}
                  disabled={!shopifyPlanPlus}
                  onClick={() => {
                    dispatch(handleChangeTermAndConditionType(TermsType.CHECKOUT));
                    navigate(PATH.TERM_AND_CONDITION_SETUP);
                  }}
                >
                  {t('customize_key')}
                </Button>
                {!isLoading && (
                  <Badge tone={termAndConditionCheckoutUI.status && shopifyPlanPlus ? 'success' : 'new'}>
                    {(shopifyPlanPlus && termAndConditionCheckoutUI.status ? t('common:enabled') : t('common:disabled'))}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </InlineGrid>
      </TermAndConditionStyled>
    </Layout>
  );
}

export default TermAndCondition;