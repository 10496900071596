import { PageType } from '@/constants/enum';
import { handleChangePreview, previewBackupSelector, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Box, Button, Grid, InlineStack, Text, TextField } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditIcon, DeleteIcon } from '@shopify/polaris-icons';
import { validateDomain, validateUrl } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { useTranslation } from 'react-i18next';
interface IUrl {
  value: string;
  isEdit: boolean;
  error?: string;
  index: number;
}
interface SelectCustomPageProps {
  listUrl: IUrl[];
  setListUrl: React.Dispatch<React.SetStateAction<IUrl[]>>;
}
const SelectCustomPage: React.FC<SelectCustomPageProps> = ({ listUrl, setListUrl }) => {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);
  const { t } = useTranslation("common");
  const { data } = apiCaller.useGetShopDomainQuery(undefined);
  const { pageTypeToShow, customPages } = previewDetail ?? {};
  const { customPages: preCustomPages } = useSelector(previewBackupSelector);
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const validDomain = useMemo(
    () =>
      data ? (data.data.domain === data.data.otherDomain ? [data.data.domain] : [data.data.domain, data.data.otherDomain]) : [],
    [data],
  );
  const handleChangeUrl = useCallback((value: string) => {
    setUrl(value);
    setError('');
  }, []);
  const handleClearInputUrl = useCallback(() => {
    setUrl('');
    setError('');
  }, []);
  const handleChangePages = useCallback(
    (value: string[]) => {
      dispatch(
        handleChangePreview({
          customPages: value,
        }),
      );
    },
    [dispatch],
  );
  const handleAddUrl = useCallback(() => {
    const trimUrl = url.trim();
    if (customPages.includes(trimUrl)) {
      setError(t('url_exist'));
    } else if (!validateUrl(trimUrl)) {
      setError(t('enter_valid'));
    } else if (!validateDomain(trimUrl, validDomain)) {
      setError(t('invalid_url'));
    } else {
      handleChangePages([...customPages, trimUrl]);
      setUrl('');
      setError('');
    }
  }, [url, customPages, validDomain, handleChangePages, t]);
  const handleRemoveUrl = useCallback(
    (index: number) => {
      const newArr = listUrl.filter((_, i) => i !== index);
      setListUrl(newArr);
      handleChangePages(newArr.map((item) => item.value));
    },
    [listUrl, setListUrl, handleChangePages],
  );
  const handleEditUrl = useCallback(
    (index: number) => {
      setListUrl((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isEdit: true } : item)));
    },
    [setListUrl],
  );
  const handleChangeInputUrl = useCallback(
    (value: string, id: string) => {
      setListUrl((prevItems) => prevItems.map((item, i) => (i.toString() === id ? { ...item, value: value, error: '' } : item)));
    },
    [setListUrl],
  );
  const handleSaveChangeUrl = useCallback(
    (index: number) => {
      const tempAddedUrls = listUrl.map((val) => val.value?.trim()).filter((_, i) => i !== index);
      let currentUrl = listUrl[index].value.trim();
      listUrl[index].value = currentUrl;
      if (tempAddedUrls.includes(currentUrl)) {
        setListUrl((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, error: 'URL already exists' } : item)));
      } else if (!validateUrl(currentUrl)) {
        setListUrl((prevItems) =>
          prevItems.map((item, i) => (i === index ? { ...item, error: 'Please enter a valid URL' } : item)),
        );
      } else if (!validateDomain(currentUrl, validDomain)) {
        setListUrl((prevItems) =>
          prevItems.map((item, i) =>
            i === index ? { ...item, error: "Invalid url. The URL must match your online store's domain" } : item,
          ),
        );
      } else {
        setListUrl((prevItems) =>
          prevItems.map((item, i) => (i === index ? { ...item, isEdit: false, error: undefined } : item)),
        );
        handleChangePages(listUrl.map((val) => val.value));
        setError('');
      }
    },
    [listUrl, validDomain, setListUrl, handleChangePages],
  );
  const handleDiscardChangeUrl = useCallback(
    (index: number) => {
      const newListUrl = listUrl.map((item, i) =>
        i === index ? { value: preCustomPages[item.index], isEdit: false, index: item.index } : item,
      );
      setListUrl(newListUrl);
      handleChangePages(newListUrl.map((val) => val.value));
    },
    [preCustomPages, listUrl, setListUrl, handleChangePages],
  );
  const listPageConmponent = useMemo(
    () =>
      listUrl.map((url, index) => (
        <BlockStack gap={"300"} key={index}>
          <div style={{ display: "flex", alignItems: "flex-start", gap: "0.5em" }}>
            <div style={{ flex: '5' }}>
              <TextField
                value={url.value}
                label={null}
                autoComplete="off"
                key={index}
                id={index.toString()}
                onChange={handleChangeInputUrl}
                // onFocus={() => handleEditUrl(index)}
                error={url.error}
                readOnly={!url.isEdit}
              />
            </div>
            <div style={{ display: "flex", gap: "0.2em" }}>
              <Button icon={EditIcon} onClick={() => handleEditUrl(index)}></Button>
              <Button icon={DeleteIcon} onClick={() => handleRemoveUrl(index)}></Button>
            </div>
          </div>
          {url.isEdit ? (
            <InlineStack gap={'150'}>
              <Button onClick={() => handleSaveChangeUrl(index)}>{t('save')}</Button>
              <Button onClick={() => handleDiscardChangeUrl(index)}>{t('cancel')}</Button>
            </InlineStack>
          ) : null}
        </BlockStack>
      )),
    [listUrl, handleSaveChangeUrl, handleEditUrl, handleRemoveUrl, handleDiscardChangeUrl, handleChangeInputUrl, t],
  );
  useEffect(() => {
    const newUrls = customPages?.map((val, i) => {
      const oldValue = listUrl.find((url) => url.value === val);
      return { value: val, isEdit: oldValue ? oldValue.isEdit : false, index: oldValue ? oldValue.index : i };
    }) ?? [];
    setListUrl(newUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPages]);
  useEffect(() => {
    setError('');
  }, [pageTypeToShow]);
  return pageTypeToShow === PageType.Custom ? (
    <Box paddingBlockStart={'300'}>
      <Grid columns={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
        <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 8, xl: 8 }}>
          <TextField
            autoComplete="off"
            value={url}
            label={null}
            placeholder={t('enter_url')}
            onChange={handleChangeUrl}
            error={error}
            clearButton={!!error.length}
            onClearButtonClick={handleClearInputUrl}
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
          <Button fullWidth size='large' variant="primary" onClick={handleAddUrl} disabled={!url.trim().length}>
            {t('add_url')}
          </Button>
        </Grid.Cell>
      </Grid>
      {listUrl.length ? (
        <Box paddingBlockStart={'400'}>
          <BlockStack gap={'300'}>
            <Text as="p">URLs</Text>
            {listPageConmponent}
          </BlockStack>
        </Box>
      ) : null}
    </Box>
  ) : null;
};

export default SelectCustomPage;
