import { formatDate, getLastMonth, getLastSomesMonth, getSomeDaysAgo } from '@/helpers';
import { Box, Button, DatePicker, Icon, OptionList, Popover, TextField, useBreakpoints } from '@shopify/polaris';
import { ArrowRightIcon, CalendarIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePickerContainer, DatePickerStyled } from './styled';
import { useSelector } from 'react-redux';
import { selectedDatePickerSelector } from '@/redux/slice/Dashboard/dashboard.slice';
import { useTranslation } from 'react-i18next';

interface Props {
  titleButton: string;
  setTitleButton: Function;
  startDate: Date;
  endDate: Date;
  isShowSelectedTime: boolean;
  onSave: Function;
  disabled?: boolean;
  conditions?: boolean;
}
const now = new Date();
const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const startHourYesterday = new Date(getSomeDaysAgo(1));
startHourYesterday.setHours(0);
startHourYesterday.setMinutes(0);
startHourYesterday.setSeconds(0);
const endHourYesterday = new Date(getSomeDaysAgo(1));
endHourYesterday.setHours(23);
endHourYesterday.setMinutes(59);
endHourYesterday.setSeconds(59);

function CustomDatePicker(props: Props) {
  const { t, i18n } = useTranslation(["dashboard", "common"]);
  const now = new Date();
  const ranges = useMemo(() => {
    return [
      {
        title: t('custom'),
        alias: 'Custom',
        titleWithTooltip: t('custom'),
        period: {
          end: endDate,
          start: startDate,
        },
        id: 0,
      },
      {
        title: t('today'),
        alias: 'today',
        period: {
          end: endDate,
          start: startDate,
        },
        titleWithTooltip: t('today'),
        id: 1,
      },
      {
        title: t('yesterday'),
        alias: 'yesterday',
        period: {
          end: endHourYesterday,
          start: startHourYesterday,
        },
        titleWithTooltip: t('yesterday'),
        id: 2,
      },
      {
        title: t('last_7_days'),
        alias: 'last7days',
        period: {
          end: endDate,
          start: getSomeDaysAgo(7),
        },
        titleWithTooltip: t('last_7_days'),
        id: 3,
      },
      {
        title: t('last_30_days'),
        alias: 'last30days',
        period: {
          end: endDate,
          start: getSomeDaysAgo(30),
        },
        // disable: props.conditions,
        titleWithTooltip: t('last_30_days'),
        id: 4,
      },
      {
        title: t('last_month'),
        alias: 'lastmonth',
        period: {
          end: getLastMonth().end,
          start: getLastMonth().start,
        },
        titleWithTooltip: t('last_month'),
        id: 5,
        // disable: props.conditions,
      },
      {
        title: t('last_60_days'),
        alias: 'last60days',
        period: {
          end: endDate,
          start: getSomeDaysAgo(60),
        },
        titleWithTooltip: t('last_60_days'),
        id: 6
      }]
  }, [t]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState(ranges[3]);
  const [selectedDates, setSelectedDates] = useState({ start: getSomeDaysAgo(7), end: endDate });
  const { mdDown } = useBreakpoints();
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);
  const selectedOption = useSelector(selectedDatePickerSelector);
  const [state, setState] = useState({
    selectedStartHour: '00',
    selectedStartMinute: '00',
    selectedEndHour: '23',
    selectedEndMinute: '59',
  });

  useEffect(() => {
    const range = ranges.find((item) => item.title === props.titleButton);
    setSelected(range || ranges[0]);
    setSelectedDates({
      end: props.endDate,
      start: props.startDate,
    });
  }, [props.endDate, props.startDate, props.titleButton, ranges]);

  useEffect(() => {
    const selectOption = ranges.find((item) => item.id === selectedOption) ?? ranges[3];
    props.setTitleButton(selectOption.title, selected.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);


  const optionsHour = useMemo(() => {
    const result = [];
    for (let i = 0; i <= 23; i++) {
      const value = i.toString().padStart(2, '0');
      result.push(
        <option key={i} value={value}>
          {value}
        </option>,
      );
    }
    return result;
  }, []);

  const optionsMinute = useMemo(() => {
    const result = [];
    for (let i = 0; i <= 59; i++) {
      const value = i.toString().padStart(2, '0');
      result.push(
        <option key={i} value={value}>
          {value}
        </option>,
      );
    }
    return result;
  }, []);

  const [showSelectTime, setShowSelectTime] = useState(false);
  const handleClickSelectTime = () => {
    setShowSelectTime(!showSelectTime);
  };

  const handleTime = () => {
    const start = new Date(selectedDates.start);
    start.setHours(showSelectTime ? Number(state.selectedStartHour) : 0, showSelectTime ? Number(state.selectedStartMinute) : 0);
    const end = new Date(selectedDates.end);
    end.setHours(showSelectTime ? Number(state.selectedEndHour) : 23, showSelectTime ? Number(state.selectedEndMinute) : 59);
    props.onSave(start, end);
  };
  return (
    <DatePickerContainer>
      <Popover
        active={popoverActive}
        autofocusTarget="none"
        preferredAlignment="left"
        preferredPosition="below"
        fluidContent
        sectioned={false}
        fullHeight
        activator={
          <Button icon={CalendarIcon} onClick={() => setPopoverActive(!popoverActive)}>
            {props.titleButton === 'Last 7 days' ? t('last_7_days') : props.titleButton}
          </Button>
        }
        onClose={() => {
          setSelectedDates({ start: props.startDate || new Date(), end: props.startDate || new Date() });
          setPopoverActive(false);
        }}
      >
        <DatePickerStyled>
          <OptionList
            options={ranges.map((range) => ({
              value: range.alias,
              label: props.conditions ? range.titleWithTooltip : range.title,
              // disabled: range.disable,
            }))}
            selected={[selected.alias]}
            onChange={(value) => {
              const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
              setSelected(range);
              setSelectedDates({ ...range.period });
              setState({
                ...state,
                selectedStartHour: '00',
                selectedStartMinute: '00',
                selectedEndHour: '23',
                selectedEndMinute: '59',
              });
              handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
            }}
          />
          <Box padding={'400'} maxWidth={mdDown ? '320px' : '516px'}>
            <div className="d-flex">
              <div>
                <TextField
                  role="combobox"
                  label={'Since'}
                  labelHidden
                  prefix={<Icon source={CalendarIcon} />}
                  value={
                    showSelectTime
                      ? formatDate(selectedDates.start, `DD/MM/YYYY, ${state.selectedStartHour}:${state.selectedStartMinute}`)
                      : formatDate(selectedDates.start, 'DD/MM/YYYY')
                  }
                  readOnly
                  autoComplete="off"
                />
              </div>
              <Icon source={ArrowRightIcon} />
              <div>
                <TextField
                  role="combobox"
                  label={'Until'}
                  labelHidden
                  prefix={<Icon source={CalendarIcon} />}
                  value={
                    showSelectTime
                      ? formatDate(selectedDates.end, `DD/MM/YYYY, ${state.selectedEndHour}:${state.selectedEndMinute}`)
                      : formatDate(selectedDates.end, 'DD/MM/YYYY')
                  }
                  autoComplete="off"
                  readOnly
                />
              </div>
            </div>
            <DatePicker
              month={month}
              year={year}
              selected={{
                start: selectedDates.start,
                end: selectedDates.end,
              }}
              onMonthChange={handleMonthChange}
              onChange={(value) => {
                setSelectedDates(value);
                setSelected(ranges[0]);
              }}
              multiMonth
              allowRange
              disableDatesAfter={now}
              disableDatesBefore={getLastSomesMonth(3).end}
            />
            {showSelectTime && (
              <div className="d-flex flex-center">
                <div className="date-picker">
                  <select
                    className="hour"
                    value={state.selectedStartHour}
                    onChange={(event) => setState({ ...state, selectedStartHour: event.target.value })}
                  >
                    {optionsHour}
                  </select>
                  :
                  <select
                    className="minute"
                    value={state.selectedStartMinute}
                    onChange={(event) => setState({ ...state, selectedStartMinute: event.target.value })}
                  >
                    {optionsMinute}
                  </select>
                </div>
                <div className="date-picker">
                  <select
                    className="hour"
                    value={state.selectedEndHour}
                    onChange={(event) => setState({ ...state, selectedEndHour: event.target.value })}
                  >
                    {optionsHour}
                  </select>
                  :
                  <select
                    className="minute"
                    value={state.selectedEndMinute}
                    onChange={(event) => setState({ ...state, selectedEndMinute: event.target.value })}
                  >
                    {optionsMinute}
                  </select>
                </div>
              </div>
            )}
            <div className="date-picker-btn-group">
              {props.isShowSelectedTime ? (
                <Button variant="plain" onClick={handleClickSelectTime}>
                  {t('select_time')}
                </Button>
              ) : null}
              <div className="ml-8">
                <Button
                  onClick={() => {
                    setSelectedDates({ start: props.startDate || new Date(), end: props.endDate || new Date() });
                    setPopoverActive(false);
                  }}
                >
                  {t('common:cancel')}
                </Button>
              </div>
              <div className="ml-8">
                {showSelectTime ? (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setPopoverActive(false);
                      // Subtract the timezone time to match the data on the database
                      handleTime();
                      props.setTitleButton(
                        selected.title === 'Custom'
                          ? `${formatDate(
                            selectedDates.start,
                            `YYYY-MM-DD ${state.selectedStartHour}:${state.selectedStartMinute}:ss`,
                          )} - ${formatDate(
                            selectedDates.end,
                            `YYYY-MM-DD ${state.selectedEndHour}:${state.selectedEndMinute}:ss`,
                          )}`
                          : selected.title,
                        selected.id
                      );
                    }}
                  >
                    {t('apply')}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setPopoverActive(false);
                      handleTime();
                      props.setTitleButton(
                        selected.title === 'Custom'
                          ? `${formatDate(selectedDates.start, 'DD/MM/YYYY')} - ${formatDate(selectedDates.end, 'DD/MM/YYYY')}`
                          : selected.title,
                        selected.id
                      );
                    }}
                  >
                    {t('apply')}
                  </Button>
                )}
              </div>
            </div>
          </Box>
        </DatePickerStyled>
      </Popover>
    </DatePickerContainer>
  );
}

export default memo(CustomDatePicker);
