import { ContextualBar } from '@/components/ContextualBar';
import useHandleContextual from '@/components/ContextualBar/HideContextual';
import CriticalBanner from '@/components/CriticalBanner';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { DisplayPages, Embedded, TermsType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import {
  clearErrorsTermAndCondition, handleChangeSetupTermAndCondition,
  handleChangeSetupTermAndConditionCheckoutUI, handlePushDataTermAndCondition,
  handlePushDataTermAndConditionCheckoutUI, setErrorsTermAndCondition, setupTermAndConditionBackupSelector,
  setupTermAndConditionCheckoutUIBackupSelector,
  setupTermAndConditionCheckoutUISelector,
  setupTermAndConditionSelector,
  termAndConditionTypeSelector
} from '@/redux/slice/termAndCondition.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import { BlockStack, InlineGrid, Modal, Tabs, useBreakpoints } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckBox from './components/CheckBox';
import ConditionTermAndCondition from './components/ConditionTermAndCondition';
import PreviewTermAndCondition from './components/PreviewTermAndCondition';
import TermAndConditionCheckoutUI from './components/TermAndConditionCheckoutUI';
import { TermAndConditionSetupStyled } from './styled';

const TermAndConditionSetup = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { mdUp } = useBreakpoints();
  const { t } = useTranslation(["term-condition", "common"]);
  const contextual = useHandleContextual();

  // Selectors
  const setupTermAndCondition = useSelector(setupTermAndConditionSelector);
  const setupTermAndConditionBackup = useSelector(setupTermAndConditionBackupSelector);
  const setupTermAndConditionCheckoutUI = useSelector(setupTermAndConditionCheckoutUISelector);
  const setupTermAndConditionCheckoutUIBackup = useSelector(setupTermAndConditionCheckoutUIBackupSelector);
  const termAndConditionType = useSelector(termAndConditionTypeSelector);
  const isShowContextualBar = useSelector(isShowSelector);

  // API calls
  const [updateTermAndCondition, { isLoading }] = apiCaller.useUpdateTermAndConditionMutation();
  const { data: checkoutData, isLoading: isLoadingCheckout } = apiCaller.useGetTermAndConditionQuery({ type: TermsType.CHECKOUT });
  const { data: storefrontData, isLoading: isLoadingStorefront } = apiCaller.useGetTermAndConditionQuery({ type: TermsType.STOREFRONT });

  // State
  const [error, setError] = useState({ status: false, message: '' });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [activeModal, setActiveModal] = useState(false);
  const isCheckoutUI = termAndConditionType === TermsType.CHECKOUT;
  const isLoadingAny = isLoading || isLoadingCheckout || isLoadingStorefront;

  // Initialize data
  useEffect(() => {
    const data = isCheckoutUI ? checkoutData : storefrontData;
    if (!data?.data || (setupTermAndCondition.id && setupTermAndConditionCheckoutUI.id)) return;

    const commonData = {
      id: data.data.id,
      shop: data.data.shop,
      status: data.data.status,
      type: data.data.type,
      condition: data.data.condition,
    };

    if (isCheckoutUI) {
      dispatch(handlePushDataTermAndConditionCheckoutUI(commonData));
    } else {
      dispatch(handlePushDataTermAndCondition({
        ...commonData,
        displayPages: data.data.displayPages.split(',') as DisplayPages[],
        checkBoxSize: data.data.checkboxSize,
        checkBoxColor: data.data.checkboxColor,
        messageContent: data.data.messageContent,
        keyword: data.data.keyword,
        link: data.data.link,
        messageError: data.data.messageError,
        messageSize: data.data.messageSize,
        messageColor: data.data.messageColor,
      }));
    }
    // Reset errors when initializing data
    dispatch(clearErrorsTermAndCondition());
  }, [checkoutData, storefrontData, dispatch, isCheckoutUI, setupTermAndCondition.id, setupTermAndConditionCheckoutUI.id]);

  // Handlers
  const handleTabChange = useCallback((tabIndex: number) => setSelectedTabIndex(tabIndex), []);

  const handleDiscard = useCallback(() => {
    const action = isCheckoutUI
      ? handleChangeSetupTermAndConditionCheckoutUI
      : handleChangeSetupTermAndCondition;
    dispatch(action(isCheckoutUI ? setupTermAndConditionCheckoutUIBackup : setupTermAndConditionBackup));
    dispatch(clearErrorsTermAndCondition());
  }, [dispatch, isCheckoutUI, setupTermAndConditionBackup, setupTermAndConditionCheckoutUIBackup]);

  const handleBackAction = useCallback(() => {
    if (isShowContextualBar && config.embedded === Embedded.LIVE) {
      contextual.hide();
    } else {
      navigate(state?.prePath || PATH.TERM_AND_CONDITION);
    }
  }, [navigate, state?.prePath, isShowContextualBar, contextual]);

  const handleSaveSetupTermAndCondition = async () => {
    setActiveModal(false);

    try {
      const payload = {
        id: isCheckoutUI ? setupTermAndConditionCheckoutUI.id : setupTermAndCondition.id,
        shop: config.shop,
        status: isCheckoutUI ? setupTermAndConditionCheckoutUI.status : setupTermAndCondition.status,
        condition: isCheckoutUI ? setupTermAndConditionCheckoutUI.condition : setupTermAndCondition.condition,
        type: termAndConditionType || TermsType.STOREFRONT,
        ...(isCheckoutUI ? {} : {
          displayPages: setupTermAndCondition.displayPages.join(','),
          checkBoxSize: +setupTermAndCondition.checkBoxSize,
          checkBoxColor: setupTermAndCondition.checkBoxColor,
          keyword: (setupTermAndCondition.keyword || '').trim() ?? t('terms_conditions_default'),
          messageContent: setupTermAndCondition.messageContent ?? t('terms_agreement_text_field'),
          messageError: setupTermAndCondition.messageError,
          messageSize: setupTermAndCondition.messageSize ? +setupTermAndCondition.messageSize : 16,
          messageColor: setupTermAndCondition.messageColor,
          link: setupTermAndCondition.link || '',
        }),
      };

      const res = await updateTermAndCondition(payload);

      if ('data' in res) {
        setError({ message: '', status: false });
        dispatch(handleToast(handleToastMutation(res, t)));
        dispatch(isCheckoutUI
          ? handlePushDataTermAndConditionCheckoutUI({ ...setupTermAndConditionCheckoutUI })
          : handlePushDataTermAndCondition({ ...setupTermAndCondition })
        );
        dispatch(clearErrorsTermAndCondition());
      } else {
        const errorDetails = checkShowErrorInline(res);
        if (errorDetails.statusCode) {
          setError({ status: true, message: errorDetails.message });
        }
      }
    } catch (error) {
      console.error('Error saving term and condition:', error);
      setError({ status: true, message: t('common:save_error') });
    }
  };

  const isDataEqual = useMemo(() => isCheckoutUI
    ? isEqual(setupTermAndConditionCheckoutUI, setupTermAndConditionCheckoutUIBackup)
    : isEqual(setupTermAndCondition, setupTermAndConditionBackup),
    [isCheckoutUI, setupTermAndCondition, setupTermAndConditionBackup, setupTermAndConditionCheckoutUI, setupTermAndConditionCheckoutUIBackup]
  );

  const isSaveDisabled = useMemo(() => {
    if (isDataEqual) return true;
    if (isCheckoutUI) return false;

    const { displayPages, checkBoxSize, messageContent, keyword } = setupTermAndCondition;

    // Chỉ kiểm tra lỗi khi các trường đã được chỉnh sửa và rỗng
    const isMessageContentInvalid = messageContent !== null && messageContent !== undefined && !messageContent.trim();
    const isKeywordInvalid = keyword !== null && keyword !== undefined && !keyword.trim();

    const hasValidationErrors = !displayPages?.length || !checkBoxSize || isMessageContentInvalid || isKeywordInvalid;

    // Update errors in Redux store
    if (hasValidationErrors) {
      dispatch(setErrorsTermAndCondition({
        ...(displayPages?.length ? {} : { displayPages: t('field_required') }),
        ...(checkBoxSize ? {} : { checkBoxSize: t('field_required') }),
        ...(isMessageContentInvalid ? { messageContent: t('field_required') } : {}),
        ...(isKeywordInvalid ? { keyword: t('field_required') } : {})
      }));
    } else {
      dispatch(clearErrorsTermAndCondition());
    }

    return hasValidationErrors;
  }, [isDataEqual, isCheckoutUI, setupTermAndCondition, dispatch, t]);

  const tabs = useMemo(() => [
    { id: 'condition', content: t('condition_key'), fields: <ConditionTermAndCondition /> },
    { id: 'check-box', content: t('checkbox'), fields: <CheckBox /> },
  ], [t]);

  const layoutSettings = config.embedded !== Embedded.LIVE ? {
    primaryAction: {
      content: t('common:save'),
      disabled: isSaveDisabled,
      loading: isLoadingAny,
      onAction: handleSaveSetupTermAndCondition,
    },
    secondaryActions: [{
      content: t('common:discard'),
      onAction: handleDiscard,
      disabled: isDataEqual,
    }],
  } : null;

  return (
    <Layout
      backAction={{ content: '', onAction: handleBackAction }}
      title={t("terms_and_conditions")}
      subtitle={t("terms_and_conditions_setup")}
      {...layoutSettings}
    >
      <TermAndConditionSetupStyled>
        {config.embedded === Embedded.LIVE && (
          <ContextualBar
            delay={state?.prePath}
            value={setupTermAndCondition}
            loadingSave={isLoadingAny}
            disabledDiscard={isDataEqual}
            disabledSave={isSaveDisabled}
            onDiscard={handleDiscard}
            onSave={handleSaveSetupTermAndCondition}
          />
        )}

        <InlineGrid gap={{ sm: '200', md: '100' }} columns={mdUp ? ['oneThird', 'twoThirds'] : 1}>
          {isCheckoutUI ? (
            <div className='mt-4'>
              <TermAndConditionCheckoutUI setActiveModal={setActiveModal} />
            </div>
          ) : (
            <BlockStack gap="100">
              <Tabs tabs={tabs} fitted selected={selectedTabIndex} onSelect={handleTabChange} />
              {tabs[selectedTabIndex].fields}
            </BlockStack>
          )}

          <div className="preview-checkout-stick">
            <BlockStack gap="100">
              <CriticalBanner isVisible={error.status}>
                <RegularText>{error.message}</RegularText>
              </CriticalBanner>
              <PreviewTermAndCondition />
            </BlockStack>
          </div>
        </InlineGrid>
      </TermAndConditionSetupStyled>

      <Modal
        open={activeModal}
        onClose={() => setActiveModal(false)}
        title="Unsaved changes"
        primaryAction={{
          content: 'Save',
          onAction: handleSaveSetupTermAndCondition,
          disabled: isSaveDisabled,
        }}
        secondaryActions={[{
          content: 'Cancel',
          onAction: () => setActiveModal(false),
        }]}
      >
        <Modal.Section>
          <RegularText>{t("save_changes_before_leaving_page")}</RegularText>
        </Modal.Section>
      </Modal>
    </Layout>
  );
};

export default TermAndConditionSetup;