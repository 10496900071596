import BoldText from '@/components/BoldText';
import ColorPickerCustom from '@/components/ColorPickerCustom';
import DropZoneCustom from '@/components/DropZoneCustom';
import { BackgroundColor, BackgroundType } from '@/constants/enum';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { BlockStack, Card, ChoiceList, Collapsible, Divider } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface BackgroundSettingProps {
  setFiles: React.Dispatch<
    React.SetStateAction<{
      logo: File[];
      backgroundImage: File[];
    }>
  >;
}

const BackgroundSetting: React.FC<BackgroundSettingProps> = ({ setFiles }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["popup"]);
  const previewDetail = useSelector(previewSelector);
  console.log('previewDetail', typeof previewDetail.mainBackgroundType)

  const choices = [
    { label: t('solid_color_background'), value: BackgroundType.BackgroundColor },
    { label: t('image_background'), value: BackgroundType.BackgroundImage },
    // { label: 'Color', value: BackgroundType.BackgroundColor },
  ];
  

  const handleChangeBackgroundType = (value: string[]) => {
    const isBackgroundColor = value[0] === BackgroundType.BackgroundColor;

    dispatch(
      handleChangePreview({
        mainBackgroundType: value[0] as BackgroundType,
        mainBackground: isBackgroundColor ? BackgroundColor.WhiteTransparent : '',
      }),
    );

    if (isBackgroundColor) {
      setFiles((prev) => ({
        ...prev,
        backgroundImage: [],
      }));
    }
  };

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  return (
    <Card>
      <BoldText>{t('pop_up_background')}</BoldText>
      <div className='mt-8 mb-8'>
        <Divider />
      </div>
      <BlockStack gap="500">
        <ChoiceList
          choices={choices}
          selected={[previewDetail.mainBackgroundType]}
          title={t('type')}
          onChange={handleChangeBackgroundType}
        />

        <Collapsible
          id="bgColor-collapsible"
          open={previewDetail.mainBackgroundType === BackgroundType.BackgroundColor}
          expandOnPrint
          transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
        >
          <ColorPickerCustom
            onChange={handleChangeTemplate('mainBackground')}
            label={t('page_background_color')}
            color={previewDetail.mainBackground}
            keyColor="mainBackground"
            defaultColor={BackgroundColor.White}
            requiredIndicator
          />
        </Collapsible>

        <Collapsible
          id="bgImage-collapsible"
          open={previewDetail.mainBackgroundType === BackgroundType.BackgroundImage}
          expandOnPrint
          transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
        >
          <DropZoneCustom
            fileDefault={previewDetail.mainBackgroundType === BackgroundType.BackgroundImage ? previewDetail.mainBackground : ''}
            label={t('page_background_image')}
            onChangeFile={(file, url) => {
              handleChangeTemplate('mainBackground')(url);
              setFiles((prev) => ({
                ...prev,
                backgroundImage: file,
              }));
            }}
            onRemove={() => {
              setFiles((prev) => ({
                ...prev,
                backgroundImage: [],
              }));
              dispatch(
                handleChangePreview({
                  mainBackground: '',
                }),
              );
            }}
          />
        </Collapsible>

        <ColorPickerCustom
          onChange={handleChangeTemplate('popupBackground')}
          label={t('background_color')}
          color={previewDetail.popupBackground}
          keyColor="popupBackground"
          defaultColor={BackgroundColor.Black}
        />

        <DropZoneCustom
          fileDefault={previewDetail.logo}
          label={t('logo_optional')}
          onChangeFile={(file, url) => {
            handleChangeTemplate('logo')(url);
            setFiles((prev) => ({
              ...prev,
              logo: file,
            }));
          }}
          onRemove={() => {
            setFiles((prev) => ({
              ...prev,
              logo: [],
            }));
            dispatch(
              handleChangePreview({
                logo: '',
              }),
            );
          }}
        />
      </BlockStack>
    </Card>
  );
};

export default BackgroundSetting;
